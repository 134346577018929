import React, {
  FunctionComponent,
  ReactElement,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '@fluentui/react/lib/Icon';

import * as DailySdrActions from '../../actions/dailySdr';

import { UserInfo } from '../../types/UserInfo';
import { State } from '../../types/State';
import { DailySdrSub } from '../../types/DailySdr';

const SheetDashBoard: FunctionComponent = (): React.ReactElement => {
  const userInfo = useSelector((state: State): UserInfo => state.app.userInfo);
  const dispatch = useDispatch();

  const subscriptions = useSelector(
    (state: State): DailySdrSub[] => state.dailySdr.subscriptions,
  );

  return subscriptions.length > 0 && (
    <div className="card card-bordered">
      <div>
        { subscriptions.map((sub): ReactElement => {
          const { sheetId, sheetName, isActive } = sub;
          return (
            <div
              key={sheetId}
              className="SheetDashboard_container"
            >
              <span>
                <Icon
                  iconName="ExcelDocument"
                />
                {` ${sheetName}`}
              </span>
              <span>
                <Icon
                  iconName={isActive ? 'PlugConnected' : 'PlugDisconnected'}
                  className={`SheetDashboard_icon ${isActive ? 'text-success' : 'text-error'}`}
                  onClick={
                    (): void => {
                      dispatch(DailySdrActions.toggleSubscription(sheetId, userInfo, dispatch));
                    }
                  }
                />
                <Icon
                  iconName="Delete"
                  className="SheetDashboard_icon text-error"
                  onClick={
                    (): void => {
                      dispatch(DailySdrActions.unsubscribe(sheetId, dispatch));
                    }
                  }
                />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SheetDashBoard;
