import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';

const Progress = (): React.ReactElement => (
  <div className="container_app">
    <div className="container_content">
      <div className="container">
        <div className="card">
          <div className="container">
            <img
              src="img/logo-blue-inset-80.png"
              alt="OtcStreaming logo"
              title="OtcStreaming logo"
            />
            <h1>
              <span className="text-bold">OTC</span>
              <span className="text-light">Streaming</span>
            </h1>
            <Spinner
              size={SpinnerSize.large}
              label="Please sideload your add-in to see app body."
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Progress;
