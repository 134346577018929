import InstanceTypes from '../types/enums/InstanceTypes';

const APP_LOCAL = 'http://localhost:39792';
const LANDING_LOCAL = 'http://localhost:5001';

export function getAppUrl(env: InstanceTypes, appEnv: string): string {
  const url = appEnv === 'debug-mode'
    ? APP_LOCAL
    : `https://${appEnv}.otcstreaming.com`;
  switch (env) {
    case InstanceTypes.Public:
      return 'https://public.otcstreaming.com';
    case InstanceTypes.Premium:
      return 'https://premium.otcstreaming.com';
    case InstanceTypes.Private:
      return url;
    default:
      return 'https://public.otcstreaming.com';
  }
}

export function getHomePageUrl(appEnv: string): string {
  switch (true) {
    case appEnv === 'debug-mode':
      return LANDING_LOCAL;
    case appEnv.includes('prx'):
      return 'https://prex.otcstreaming.com';
    default:
      return 'https://www.otcstreaming.com';
  }
}
