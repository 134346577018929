import { Dispatch } from 'redux';

import * as Type from '../constants/app';

import { UserInfo } from '../types/UserInfo';
import { Error } from '../types/State';
import { Action } from '../types/Action';
import {
  InitializeAction,
  OnDeleteExcelWorkSheetAction,
  SetSessionInfoAction,
  ErrorPushAction,
} from '../types/App';

export const officeIsInitialized: Action = {
  type: Type.OFFICE_INITIALIZED,
};

export const appInitialize = (
  userInfo: UserInfo,
  dispatch: Dispatch,
): InitializeAction => ({
  type: Type.APP_INITIALIZE,
  payload: {
    userInfo,
    dispatch,
  },
});

export const setSessionInfos = (data: UserInfo): SetSessionInfoAction => ({
  type: Type.SET_SESSION_INFOS,
  payload: {
    token: data.token,
    userName: data.userName,
    env: data.env,
    appEnv: data.appEnv,
  },
});

export const logout: Action = {
  type: Type.LOGOUT,
};

export const errorClose: Action = ({
  type: Type.ERROR_CLOSE,
});

export const errorPush = (error: Error): ErrorPushAction => ({
  type: Type.ERROR_PUSH,
  payload: error,
});

export const existingSheetDialogOpen: Action = ({
  type: Type.EXISTING_SHEET_DIALOG_OPEN,
});

export const existingSheetDialogClose: Action = ({
  type: Type.EXISTING_SHEET_DIALOG_CLOSE,
});

export const existingSheetDialogYes: Action = ({
  type: Type.EXISTING_SHEET_DIALOG_YES,
});

export const existingSheetDialogNo: Action = ({
  type: Type.EXISTING_SHEET_DIALOG_NO,
});

export const onDeleteExcelWorkSheet = (
  sheetId: string,
  dispatch: Dispatch,
): OnDeleteExcelWorkSheetAction => ({
  type: Type.ON_DELETED_EXCEL_WORKSHEET,
  payload: {
    sheetId,
    dispatch,
  },
});
