import { Dispatch } from 'redux';
import { Standard } from '@ht/otcstreaming';

import * as RealtimeTickerActions from '../actions/realtimeTicker';
import * as AppActions from '../actions/app';

import { getSheetNameById } from './workSheets';
import { getSpread, getUpfront } from '../utils/prices';
import { SUBSCRIPTIONS } from '../constants/realtimeTicker';

import { Best } from '../types/Best';
import { Result } from '../types/Result';
import { RealtimeTickerSub } from '../types/RealtimeTicker';
import { getRangeInfoFromAddress, isNullOrEmptyString } from '../utils/helpers';
import { parseDate, maxDate, dateToOADate } from '../utils/date';
import * as Color from '../constants/color';
import * as Format from '../constants/format';

function handleSaveResult(
  dispatch: Dispatch,
  asyncResult: Office.AsyncResult<void>,
): void {
  if (asyncResult.status === Office.AsyncResultStatus.Failed) {
    const error = {
      title: 'Failed to save',
      message: 'The feed may be unable to restart the next time you open the Add-in.',
    };
    dispatch(AppActions.errorPush(error));
  }
}

export function saveSubscription(
  dispatch: Dispatch,
  sub: RealtimeTickerSub,
): void {
  const data = Office.context.document.settings.get(SUBSCRIPTIONS);
  const subscriptions = data == null ? [] : JSON.parse(data);
  Office.context.document.settings.set(
    SUBSCRIPTIONS,
    JSON.stringify([...subscriptions, sub]),
  );

  Office.context.document.settings.saveAsync(
    (asyncResult: Office.AsyncResult<void>): void => handleSaveResult(dispatch, asyncResult),
  );
}

export function removeSubscription(
  dispatch: Dispatch,
  sheetId: string,
  rangeAddress?: string,
): void {
  const data = Office.context.document.settings.get(SUBSCRIPTIONS);
  let subscriptions: RealtimeTickerSub[] = data == null ? [] : JSON.parse(data);
  subscriptions = subscriptions.filter(
    (sub: RealtimeTickerSub): boolean => (
      !(sheetId === sub.sheetId && rangeAddress == null)
      && !(sheetId === sub.sheetId && rangeAddress !== null && rangeAddress === sub.rangeAddress)
    ),
  );
  Office.context.document.settings.set(SUBSCRIPTIONS, JSON.stringify(subscriptions));

  Office.context.document.settings.saveAsync(
    (asyncResult: Office.AsyncResult<void>): void => handleSaveResult(dispatch, asyncResult),
  );
}

export function updateSubscription(
  dispatch: Dispatch,
  sub: RealtimeTickerSub,
): void {
  const data = Office.context.document.settings.get(SUBSCRIPTIONS);
  let subscriptions: RealtimeTickerSub[] = data == null ? [] : JSON.parse(data);
  subscriptions = subscriptions.map((s) => {
    if (s.sheetId !== sub.sheetId || s.rangeAddress !== sub.rangeAddress) {
      return s;
    }

    return sub;
  });

  Office.context.document.settings.set(SUBSCRIPTIONS, JSON.stringify(subscriptions));

  Office.context.document.settings.saveAsync(
    (asyncResult: Office.AsyncResult<void>): void => handleSaveResult(dispatch, asyncResult),
  );
}

export async function getTickersFromRange(
  sheetId: string,
  rangeAddress: string,
): Promise<Result<{ tickers: string[]; sheetName: string; }>> {
  return Excel.run(
    { delayForCellEdit: true },
    async (ctx): Promise<Result<{ tickers: string[]; sheetName: string; }>> => {
      const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId);
      const range = sheet.getRange(rangeAddress);
      range.load('values');
      sheet.load('name');
      await ctx.sync();

      return {
        data: {
          tickers: range.values != null ? range.values.flat() : null,
          sheetName: sheet.isNullObject ? null : sheet.name,
        },
      };
    },
  ).catch(() => ({
    error: {
      title: 'Failed to get RealTime tickers',
      message: `An unexpected error occurred while getting tickers from ${rangeAddress}.`,
    },
  }));
}

export async function createSubscription(
  hasHeaders: boolean,
  subscriptions: RealtimeTickerSub[],
): Promise<Result<RealtimeTickerSub>> {
  const title = 'Realtime Ticker Data error';

  return Excel.run(
    { delayForCellEdit: true },
    async (ctx): Promise<Result<RealtimeTickerSub>> => {
      const ranges = ctx.workbook.getSelectedRanges();
      ranges.areas.load('items');
      await ctx.sync();

      const { items } = ranges.areas;
      if (items.length !== 1) {
        const error = {
          title,
          message: 'You must select one and only one range.',
        };
        return { error };
      }

      const range = items[0];
      range.worksheet.load('id');
      range.load(['columnCount', 'rowCount', 'address']);
      await ctx.sync();

      const rangeInfo = getRangeInfoFromAddress(range.address);

      if (hasHeaders && rangeInfo.startCell.row < 3) {
        const error = {
          title,
          message: 'Need at least 2 lines above the range to display headers.',
        };
        return { error };
      }

      if (['XET', 'XEU', 'XEV', 'XEW', 'XEX', 'XEY', 'XEZ', 'XFA', 'XFB', 'XFC', 'XFD']
        .includes(rangeInfo.startCell.column)
      ) {
        const error = {
          title,
          message: 'Need at least 11 columns after the range to display the data.',
        };
        return { error };
      }

      if (range.columnCount > 1 || range.rowCount < 1) {
        const error = {
          title,
          message: 'Not a valid range. Selection must be a single column.',
        };
        return { error };
      }

      const intersections = subscriptions
        .filter((sub) => sub.sheetId === range.worksheet.id)
        .map((sub): Excel.Range => {
          const intersectionRange = range.worksheet
            .getRange(sub.rangeAddress)
            ?.getIntersectionOrNullObject(range);
          return intersectionRange;
        });

      await ctx.sync();

      if (!intersections.every((i) => i.isNullObject)) {
        const error = {
          title,
          message: 'Part of the range is already connected to the feed.',
        };
        return { error };
      }

      return {
        data: {
          isActive: true,
          sheetId: range.worksheet.id,
          sheetName: rangeInfo.sheetName,
          rangeAddress: rangeInfo.rangeAddress,
        },
      };
    },
  ).catch(() => ({
    error: {
      title,
      message: 'An unexpected error occurred while creating the subscription.',
    },
  }));
}

function addFormat(range: Excel.Range, dataRange: Excel.Range, rowCount: number): void {
  [1, 4, 7].forEach((n: number): void => {
    dataRange.getColumn(n).numberFormat = [[Format.PERCENTAGE]];
  });
  [2, 3, 6].forEach((n: number): void => {
    dataRange.getColumn(n).numberFormat = [[Format.NUMBER]];
  });
  dataRange.getColumn(9).numberFormat = [[Format.NUMBER]];
  dataRange.getColumn(10).numberFormat = [[Format.DATE]];

  [range, dataRange].forEach((r: Excel.Range): void => {
    r.unmerge();
    r.format.font.size = 11;
    r.format.font.color = Color.TABLE_BLACK;
    r.format.horizontalAlignment = Excel.HorizontalAlignment.general;
    for (let i = 0; i < rowCount; i++) {
      if (i % 2 === 0) {
        r.getRow(i).format.fill.color = Color.TABLE_ROW_EVEN;
        [Excel.BorderIndex.edgeTop, Excel.BorderIndex.edgeBottom].forEach(
          (pos): void => {
            r.getRow(i).format.borders.getItem(pos).color = Color.TABLE_BORDER_COLOR;
          },
        );
      } else {
        r.getRow(i).format.fill.color = Color.TABLE_ROW_ODD;
      }
    }
    r.format.autofitColumns();
    r.format.autofitRows();
  });

  [
    Excel.BorderIndex.edgeTop,
    Excel.BorderIndex.edgeRight,
    Excel.BorderIndex.edgeBottom,
    Excel.BorderIndex.edgeLeft,
  ].forEach((edge): void => {
    range.format.borders.getItem(edge).style = 'Dash';
    range.format.borders.getItem(edge).color = Color.TABLE_BLACK;
  });
}

function drawHeader(range: Excel.Range): void {
  const firstCell = range.getCell(0, 0);

  const bidTitleCell = firstCell
    .getOffsetRange(-2, 1)
    .getResizedRange(0, 2);
  bidTitleCell.values = [['Best Bid', '', '']];

  const bidPriceFormatCell = firstCell
    .getOffsetRange(-1, 1)
    .getResizedRange(0, 2);
  bidPriceFormatCell.values = [['Source', 'Upfront', 'Spread']];

  const offerTitleCell = firstCell
    .getOffsetRange(-2, 4)
    .getResizedRange(0, 2);
  offerTitleCell.values = [['Best Offer', '', '']];

  const lastTradeTitleCell = firstCell
    .getOffsetRange(-2, 7)
    .getResizedRange(0, 2);
  lastTradeTitleCell.values = [['Last Trade', '', '']];

  const lastTradePriceFormatCell = firstCell
    .getOffsetRange(-1, 7)
    .getResizedRange(0, 2);
  lastTradePriceFormatCell.values = [['Spread', 'Upfront', 'Source']];

  const volumeTitleCell = firstCell
    .getOffsetRange(-2, 10);
  volumeTitleCell.values = [['Volume']];

  const volumeColumnCell = firstCell
    .getOffsetRange(-1, 10);
  volumeColumnCell.values = [['Daily (M)']];

  const offerPriceFormatCell = firstCell
    .getOffsetRange(-1, 4)
    .getResizedRange(0, 2);
  offerPriceFormatCell.values = [['Spread', 'Upfront', 'Source']];

  const refreshCell = firstCell
    .getOffsetRange(-2, 11)
    .getResizedRange(1, 0);
  refreshCell.values = [['Last'], ['Refresh']];
  refreshCell.format.horizontalAlignment = Excel.HorizontalAlignment.center;

  [
    bidTitleCell,
    bidPriceFormatCell,
    offerTitleCell,
    lastTradeTitleCell,
    lastTradePriceFormatCell,
    volumeTitleCell,
    offerPriceFormatCell,
    volumeColumnCell,
    refreshCell,
  ].forEach((cell): void => {
    cell.format.fill.color = Color.TABLE_HEADER_BACKGROUND;
    cell.format.font.size = 12;
    cell.format.font.color = Color.TABLE_HEADER_FONT;
  });

  [
    bidTitleCell,
    offerTitleCell,
    lastTradeTitleCell,
    volumeTitleCell,
  ].forEach((cell): void => {
    cell.merge();
    cell.format.horizontalAlignment = 'Center';
    cell.format.font.size = 14;
  });
}

function defineDataRange(range: Excel.Range): Excel.Range {
  return range.getOffsetRange(0, 1).getResizedRange(0, 10);
}

export async function createTable(
  hasHeaders: boolean,
  sub: RealtimeTickerSub,
): Promise<Result<null>> {
  const { sheetId, rangeAddress } = sub;

  return Excel.run(
    { delayForCellEdit: true },
    async (ctx): Promise<Result<null>> => {
      const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId);
      const range = sheet.getRange(rangeAddress);
      const dataRange = defineDataRange(range);
      dataRange.load('rowCount');
      await ctx.sync();

      if (hasHeaders) {
        drawHeader(range);
      }
      addFormat(range, dataRange, dataRange.rowCount);
      await ctx.sync();

      return { data: null };
    },
  ).catch(() => ({
    error: {
      title: 'Realtime Ticker Data error',
      message: `Failed to create data table for range ${rangeAddress}`,
    },
  }));
}

function addStandardToRow(row: (string | number)[], standard: Standard, reverse = false): Date {
  if (standard == null) {
    for (let i = 0; i < 3; i++) {
      row.push('');
    }
    return null;
  }

  if (reverse) {
    row.push(getSpread(standard));
    row.push(getUpfront(standard));
    row.push(standard.dealer);
  } else {
    row.push(standard.dealer);
    row.push(getUpfront(standard));
    row.push(getSpread(standard));
  }

  return parseDate(standard.valueDate);
}

function defineRow(rowData: Best): (string | number)[] {
  const {
    bid,
    offer,
    level,
    volume,
  } = rowData;

  const row: (string | number)[] = [];
  let lastRefreshDate: Date = null;

  lastRefreshDate = maxDate(addStandardToRow(row, bid), lastRefreshDate);
  lastRefreshDate = maxDate(addStandardToRow(row, offer, true), lastRefreshDate);
  lastRefreshDate = maxDate(addStandardToRow(row, level, true), lastRefreshDate);

  row.push(volume !== 0 ? volume / 1_000_000 : null);
  row.push(lastRefreshDate == null ? null : dateToOADate(lastRefreshDate));

  return row;
}

function drawTable(dataRange: Excel.Range, data: Best[]): void {
  const emptyRow = [...Array(11).fill(null)];
  dataRange.values = data.map((row): string[] => (row == null ? emptyRow : defineRow(row)));
}

export async function updateData(
  sheetId: string,
  rangeAddress: string,
  data: Best[],
): Promise<Result<null>> {
  return Excel.run(
    { delayForCellEdit: true },
    async (ctx): Promise<Result<null>> => {
      const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId);
      const range = sheet.getRange(rangeAddress);
      const dataRange = defineDataRange(range);
      dataRange.load('values');
      await ctx.sync();

      drawTable(dataRange, data);
      await ctx.sync();
      return { data: null };
    },
  ).catch(() => ({
    error: {
      title: 'Realtime Ticker Data error',
      message: `Failed to update data for range ${rangeAddress}`,
    },
  }));
}

export async function reconnectSubscriptions(
  dispatch: Dispatch,
): Promise<void> {
  const subscriptions: RealtimeTickerSub[] = JSON.parse(
    Office.context.document.settings.get(SUBSCRIPTIONS),
  );
  if (subscriptions != null) {
    dispatch(RealtimeTickerActions.init(subscriptions));
    subscriptions.forEach((sub: RealtimeTickerSub): void => {
      getSheetNameById(sub.sheetId)
        .then((sheetName: string): void => {
          if (isNullOrEmptyString(sheetName)) {
            dispatch(RealtimeTickerActions.unsubscribe(dispatch, sub.sheetId));
          } else if (sub.sheetName !== sheetName) {
            dispatch(RealtimeTickerActions.updateSubscription({ ...sub, sheetName }));
          }
        });
    });
  }
}
