import createReducer from '../utils/createReducer';

import * as Type from '../constants/auth';

import { StateAuth } from '../types/State';
import { SetLoginErrorAction } from '../types/Auth';

const initialState: StateAuth = {
  loginError: null,
};

export default createReducer(initialState, {
  [Type.LOGIN_FAILURE](
    state: StateAuth,
    action: SetLoginErrorAction,
  ): StateAuth {
    const { description } = action.payload;
    return {
      ...state,
      loginError: description,
    };
  },
});
