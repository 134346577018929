import React, { FunctionComponent, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';

import * as AppActions from '../../actions/app';

import { Error } from '../../types/State';
import { Action } from '../../types/Action';
import { isNullOrEmptyString } from '../../utils/helpers';

const ErrorDialog: FunctionComponent<{error: Error}> = ({
  error,
}): ReactElement => {
  const dispatch = useDispatch();

  const { title, message } = error;
  const definedTitle = isNullOrEmptyString(title) ? 'Error' : title;

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: definedTitle,
        subText: message,
      }}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={(): Action => dispatch(AppActions.errorClose)}
          text="Close"
        />
      </DialogFooter>
    </Dialog>
  );
};

export default ErrorDialog;
