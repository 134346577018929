import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'html5-history-api';

import '../css/main.scss';

import { initializeIcons } from '@fluentui/react/lib/Icons';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import rootReducers from './reducers/reducers';
import rootSaga from './sagas/sagas';

import App from './components/App';
import Auth from './components/Auth';

import { officeIsInitialized } from './actions/app';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducers,
  applyMiddleware(sagaMiddleware),
);

initializeIcons();
sagaMiddleware.run(rootSaga);
// eslint-disable-next-line no-undef
Office.initialize = (): void => {
  store.dispatch(officeIsInitialized);
  OfficeExtension.config.extendedErrorLogging = true;
};

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
