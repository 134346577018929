import React, { FunctionComponent, ReactElement } from 'react';

import {
  Dialog,
  DialogType,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';

interface ILoadingDialogProps {
  isLoading: boolean;
  title: string;
  subText: string;
}

const LoadingDialog: FunctionComponent<ILoadingDialogProps> = ({
  isLoading,
  title,
  subText,
}): ReactElement => (
  <Dialog
    hidden={!isLoading}
    title={title}
    dialogContentProps={{
      type: DialogType.normal,
      subText,
    }}
    modalProps={{
      isBlocking: true,
    }}
  >
    <Spinner size={SpinnerSize.large} />
  </Dialog>
);

export default LoadingDialog;
