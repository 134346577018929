import {
  takeLeading,
  call,
  all,
  put,
  takeEvery,
  select,
  delay,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import * as Type from '../constants/realtimeTicker';

import * as AppActions from '../actions/app';
import * as RealtimeTickerActions from '../actions/realtimeTicker';
import * as RealtimeTickerExcel from '../excel/realtimeTicker';
import api from '../utils/fetch';

import { Best } from '../types/Best';
import { State } from '../types/State';
import {
  RealtimeTickerSub,
  UnsubscribeAction,
  SubscribeAction,
  ToggleAction,
  StartLoopAction,
  LoopAction,
} from '../types/RealtimeTicker';
import { isResultError, isNullOrEmptyString } from '../utils/helpers';
import { Result, ErrorResult } from '../types/Result';
import { UserInfo } from '../types/UserInfo';

function* refreshSubscriptions(userInfo: UserInfo): SagaIterator {
  let subscriptions: RealtimeTickerSub[] = yield select(
    (state: State): RealtimeTickerSub[] => state.realtimeTicker.subscriptions,
  );

  subscriptions = subscriptions.filter((s) => s.isActive);
  const tickersResult: Result<{
    tickers: string[];
    sheetName: string;
  }>[] = yield all(subscriptions.map(
    (s) => call(RealtimeTickerExcel.getTickersFromRange, s.sheetId, s.rangeAddress),
  ));

  yield all(tickersResult.filter((r) => isResultError(r))
    .map((r: ErrorResult) => put(AppActions.errorPush(r.error))));

  const subTicker: { tickers: string[]; sub: RealtimeTickerSub; }[] = [];
  for (let i = 0; i < tickersResult.length; i++) {
    const res = tickersResult[i];
    if (isResultError(res)) {
      yield put(AppActions.errorPush(res.error));
    } else {
      const sub = subscriptions[i];
      const { sheetName, tickers } = res.data;
      if (sub.sheetName !== res.data.sheetName) {
        yield put(RealtimeTickerActions.updateSubscription({ ...sub, sheetName }));
      }
      subTicker.push({
        tickers,
        sub,
      });
    }
  }

  const tickers = [...(new Set(subTicker
    .flatMap((st) => st.tickers)
    .filter((t) => !isNullOrEmptyString(t)))
  )];
  const tickerRequest: Result<Best>[] = yield all(tickers.map(
    (ticker) => call(api.realTimeTicker, userInfo, ticker),
  ));
  const tickerBest: { [key: string]: Best;} = tickerRequest.reduce((obj, res, i) => ({
    ...obj,
    [tickers[i]]: isResultError(res) ? null : res.data,
  }), {});

  const results: Result<null>[] = yield all(subTicker.map((st) => {
    const data = st.tickers.map((t) => tickerBest[t]);
    const { sheetId, rangeAddress } = st.sub;
    return call(RealtimeTickerExcel.updateData, sheetId, rangeAddress, data);
  }));

  const errors = results.map((r) => (isResultError(r) ? r.error : null)).filter((e) => e != null);
  if (errors.length > 0) {
    yield all(errors.map((e) => put(AppActions.errorPush(e))));
  }
}

function* startLoop(action: StartLoopAction): SagaIterator {
  const { dispatch, userInfo } = action.payload;
  yield put(RealtimeTickerActions.loop(dispatch, userInfo));
}

function* loop(action: LoopAction): SagaIterator {
  const { dispatch, userInfo } = action.payload;
  const isActive: boolean = yield select(
    (state: State): boolean => state.realtimeTicker.isRealtimeActive,
  );
  if (isActive) {
    yield call(refreshSubscriptions, userInfo);
    yield delay(5000);
    yield put(RealtimeTickerActions.loop(dispatch, userInfo));
  }
}

function* subscribe(action: SubscribeAction): SagaIterator {
  const { hasHeaders, dispatch, userInfo } = action.payload;
  const subscriptions: RealtimeTickerSub[] = yield select(
    (state: State): RealtimeTickerSub[] => state.realtimeTicker.subscriptions,
  );

  yield put(RealtimeTickerActions.setStatusToSaving);
  const result = yield call(RealtimeTickerExcel.createSubscription, hasHeaders, subscriptions);
  if (isResultError(result)) {
    yield put(AppActions.errorPush(result.error));
  } else {
    yield put(RealtimeTickerActions.storeSubscription(result.data));
    yield call(RealtimeTickerExcel.saveSubscription, dispatch, result.data);
    yield put(RealtimeTickerActions.setStatusToFetching);
    const createTableRes = yield call(RealtimeTickerExcel.createTable, hasHeaders, result.data);
    if (isResultError(createTableRes)) {
      yield put(AppActions.errorPush(createTableRes.error));
    } else {
      yield call(refreshSubscriptions, userInfo);
    }
  }
  yield put(RealtimeTickerActions.setStatusToDefault);
}

function* toggleSubscription(action: ToggleAction): SagaIterator {
  const {
    sheetId,
    rangeAddress,
    userInfo,
    dispatch,
  } = action.payload;
  const subscriptions: RealtimeTickerSub[] = yield select(
    (state: State): RealtimeTickerSub[] => state.realtimeTicker.subscriptions,
  );
  const sub = subscriptions.filter((s) => s.sheetId === sheetId
    && s.rangeAddress === rangeAddress)[0];
  sub.isActive = !sub.isActive;

  yield call(RealtimeTickerExcel.updateSubscription, dispatch, sub);
  yield put(RealtimeTickerActions.updateSubscription(sub));
  if (sub.isActive) {
    yield call(refreshSubscriptions, userInfo);
  }
}

function* unsubscribe(action: UnsubscribeAction): SagaIterator {
  const { dispatch, sheetId, rangeAddress } = action.payload;
  yield call(RealtimeTickerExcel.removeSubscription, dispatch, sheetId, rangeAddress);
}

export default function* watchRealtimeTicker(): SagaIterator {
  yield takeLeading(Type.START_LOOP, startLoop);
  yield takeEvery(Type.LOOP, loop);
  yield takeLeading(Type.SUBSCRIBE, subscribe);
  yield takeEvery(Type.TOGGLE, toggleSubscription);
  yield takeEvery(Type.UNSUBSCRIBE, unsubscribe);
}
