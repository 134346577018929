import { Link, PrimaryButton } from '@fluentui/react';
import React, { FunctionComponent, ReactElement } from 'react';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as AuthActions from '../../actions/auth';
import Header from '../Header';
import Footer from '../Footer';

import { AuthOpenDialogAction } from '../../types/Auth';

interface IIntroDispatch {
  handleClickStart: () => void;
}

type IAuthProps = IIntroDispatch;

const Intro: FunctionComponent<IAuthProps> = ({
  handleClickStart,
}): ReactElement => (
  <div className="container_app">
    <div className="container_content">
      <Header />
      <div className="container">
        <div className="card">
          <h1>Excel add-in</h1>
          <section>
            <h2>Introduction</h2>
            <p>
              {'This Add-in targets professionals and academics who want to access, study, and follow '
              + 'the credit derivatives market. Access to the public version data is free, forever, '
              + 'and accessible to everyone. Just sign up to '}
              <Link
                href="https://www.otcstreaming.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                www.otcstreaming.com
              </Link>
              {' to access to the data!'}
            </p>
          </section>
          <section>
            <h2>Features</h2>
            <p>
              {'Based on the latest Microsoft Office integration technology, Office.js, our Add-in '
              + 'allows you to easily connect your worksheet to OTCStreaming real time and historical '
              + 'data. Office.js technology offers the flexibility of Excel’s macros. If you have any '
              + 'suggestion, do not hesitate to contact us at '}
              <Link
                href="mailto:support@otcstreaming.com"
              >
                support@otcstreaming.com
              </Link>
              .
            </p>
          </section>
          <section>
            <h2>Privacy Statement</h2>
            <p>
              {'The add-in never collects, stores, or transmits information that can be used to '
              + 'identify you. The add-in does collect anonymous information about how the add-in '
              + 'is used, such as how often it\'s launched and which buttons get clicked. '
              + 'The information collected is used only to improve the quality of the add-in '
              + 'and is not sent or sold to any third party. For more details see '}
              <Link
                href="https://otcstreaming.com/addin-privacy"
                target="_blank"
                rel="noreferrer noopener"
              >
                privacy policy
              </Link>
              .
            </p>
          </section>
          <div className="container_btn">
            <PrimaryButton
              text="Get Started"
              onClick={handleClickStart}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
);

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IIntroDispatch => ({
  handleClickStart: (): AuthOpenDialogAction => (
    dispatch(AuthActions.authDialogOpen(dispatch))
  ),
});

export default connect<null, IIntroDispatch>(null, mapDispatchToProps)(Intro);
