import { prefixMaker } from '../utils/string';

const prefix = prefixMaker('DAILYSDR');

export const SUBSCRIPTIONS = prefix('SUBSCRIPTIONS');

export const HUB_CALLBACK_METHOD_STANDARD = 'standard';

export const INIT = prefix('INIT');

export const STATUS_DEFAULT = prefix('STATUS_DEFAULT');
export const STATUS_FETCHING = prefix('STATUS_FETCHING');
export const STATUS_SAVING = prefix('STATUS_SAVING');

export const STORE_SUBSCRIPTION = prefix('STORE_SUBSCRIPTION');
export const SUBSCRIBE = prefix('SUBSCRIBE');
export const UNSUBSCRIBE = prefix('UNSUBSCRIBE');
export const RENAME_SHEET = prefix('RENAME_SHEET');
export const TOGGLE = prefix('TOGGLE');
export const REFRESH = prefix('REFRESH');
export const UPDATE_SUBSCRIPTION = prefix('UPDATE_SUBSCRIPTION');

export const ON_STANDARD = prefix('ON_STANDARD');

export const HUB_CONNECT = prefix('HUB_CONNECT');
export const HUB_CONNECTED = prefix('HUB_CONNECTED');
