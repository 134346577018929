import * as Type from '../constants/histo';

import createReducer from '../utils/createReducer';

import { StateHisto } from '../types/State';
import LoadingStatus from '../types/enums/LoadingStatus';

const initialState: StateHisto = {
  loadingStatus: LoadingStatus.default,
};

export default createReducer(initialState, {
  [Type.STATUS_DEFAULT](
    state: StateHisto,
  ): StateHisto {
    return {
      ...state,
      loadingStatus: LoadingStatus.default,
    };
  },
  [Type.STATUS_FETCHING](
    state: StateHisto,
  ): StateHisto {
    return {
      ...state,
      loadingStatus: LoadingStatus.fetching,
    };
  },
  [Type.STATUS_SAVING](
    state: StateHisto,
  ): StateHisto {
    return {
      ...state,
      loadingStatus: LoadingStatus.saving,
    };
  },
});
