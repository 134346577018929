import { Dispatch } from 'redux';
import {
  Standard,
  Price,
  ProductType,
  PriceFormat,
  StandardAction,
} from '@ht/otcstreaming';

import * as AppActions from '../actions/app';
import * as DailySdrActions from '../actions/dailySdr';

import * as Format from '../constants/format';
import * as Color from '../constants/color';

import { getSheetNameById } from './workSheets';

import { UserInfo } from '../types/UserInfo';
import { Result } from '../types/Result';
import { DailySdrSub, DailySdrUpdateResult } from '../types/DailySdr';

import { isNullOrEmptyString } from '../utils/helpers';
import { dateToOADate, parseDate } from '../utils/date';
import { SUBSCRIPTIONS } from '../constants/dailySdr';

function handleSaveResult(
  asyncResult: Office.AsyncResult<void>,
  dispatch: Dispatch,
): void {
  if (asyncResult.status === Office.AsyncResultStatus.Failed) {
    const error = {
      title: 'Failed to save',
      message: 'The worksheet may be unable to reconnect to the realtime the next time you '
        + 'open the Add-in',
    };
    dispatch(AppActions.errorPush(error));
  }
}

export function saveSubscription(
  sub: DailySdrSub,
  dispatch: Dispatch,
): void {
  const data = Office.context.document.settings.get(SUBSCRIPTIONS);
  const subscriptions = data == null ? [] : JSON.parse(data);
  Office.context.document.settings.set(
    SUBSCRIPTIONS,
    JSON.stringify([...subscriptions, sub]),
  );

  Office.context.document.settings.saveAsync(
    (asyncResult: Office.AsyncResult<void>): void => handleSaveResult(asyncResult, dispatch),
  );
}

export function removeSubscription(
  sheetId: string,
  dispatch: Dispatch,
): void {
  const data = Office.context.document.settings.get(SUBSCRIPTIONS);
  let subscriptions: DailySdrSub[] = data == null ? [] : JSON.parse(data);
  subscriptions = subscriptions.filter(
    (sub): boolean => sub.sheetId !== sheetId,
  );
  Office.context.document.settings.set(SUBSCRIPTIONS, JSON.stringify(subscriptions));

  Office.context.document.settings.saveAsync(
    (asyncResult: Office.AsyncResult<void>): void => handleSaveResult(asyncResult, dispatch),
  );
}

export function updateSubscription(
  sub: DailySdrSub,
  dispatch: Dispatch,
): void {
  const data = Office.context.document.settings.get(SUBSCRIPTIONS);
  let subscriptions: DailySdrSub[] = data == null ? [] : JSON.parse(data);
  subscriptions = subscriptions.map((s) => {
    if (s.sheetId !== sub.sheetId) {
      return s;
    }
    return sub;
  });
  Office.context.document.settings.set(SUBSCRIPTIONS, JSON.stringify(subscriptions));

  Office.context.document.settings.saveAsync(
    (asyncResult: Office.AsyncResult<void>): void => handleSaveResult(asyncResult, dispatch),
  );
}

function getPriceByFormat(prices: Price[], format: PriceFormat): number {
  const price = prices.find((p: Price): boolean => p.format === format);
  return price != null ? price.value : 0;
}

function defineRow(row: Standard): (string | number)[] {
  return [
    dateToOADate(parseDate(row.valueDate)),
    row.productMnemonic,
    ProductType[row.productType],
    row.prices != null ? getPriceByFormat(row.prices, PriceFormat.Spread) : null,
    row.prices != null ? getPriceByFormat(row.prices, PriceFormat.Price) / 100 : null,
    row.size != null ? row.size / 1_000_000 : null,
    row.dealer,
    StandardAction[row.action],
  ];
}

export async function updateTable(
  sds: Standard,
  sub: DailySdrSub,
  dispatch: Dispatch,
): Promise<Result<DailySdrUpdateResult>> {
  return Excel.run(
    { delayForCellEdit: true },
    async (ctx): Promise<Result<DailySdrUpdateResult>> => {
      const { sheetId, tableId, sheetName } = sub;
      const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId);
      sheet.load('name');
      sheet.tables.load('items');
      await ctx.sync();

      const table = sheet.tables.items.find((t) => t.id === tableId);

      if (!sheet.isNullObject && table != null) {
        table.rows.add(1, [defineRow(sds)]);
        table.getDataBodyRange().format.autofitColumns();
        table.sort.reapply();
        await ctx.sync();
        return {
          data: {
            subscription: {
              ...sub,
              sheetName: sheet.name,
            },
            isSheetRenamed: sheet.name !== sheetName,
            isSheetDeleted: false,
          },
        };
      }
      dispatch(DailySdrActions.unsubscribe(sheetId, dispatch));
      return { data: { subscription: sub, isSheetDeleted: true } };
    },
  ).catch(() => ({
    error: {
      title: 'Failed to update SDR table',
      message: 'An unexpected error occurred while updating an SDR table.',
    },
  }));
}

async function drawTable(
  sheet: Excel.Worksheet,
  data: Standard[],
): Promise<Excel.Table> {
  const columnNames: (string | number)[][] = [
    ['Time', 'Ticker', 'Instrument', 'Spread', 'Cash Price', 'Size (M)', 'Provider', 'Action'],
  ];
  let rows = data.map(
    (row: Standard): (string | number)[] => defineRow(row),
  );
  rows = columnNames.concat(rows);

  const tableRange = `A2:H${rows.length + 1}`;
  const dataRange = sheet.getRange(tableRange);
  dataRange.values = rows;
  dataRange.untrack();

  const table = sheet.tables.add(tableRange, true);

  table.sort.apply([
    {
      key: 0,
      ascending: false,
    },
  ]);

  table.columns.getItem('Spread').getDataBodyRange().numberFormat = [[Format.NUMBER]];
  table.columns.getItem('Cash Price').getDataBodyRange().numberFormat = [[Format.PERCENTAGE]];
  table.columns.getItem('Size (M)').getDataBodyRange().numberFormat = [[Format.NUMBER]];
  table.columns.getItem('Time').getDataBodyRange().numberFormat = [[Format.DATE]];
  table.getDataBodyRange().format.autofitColumns();
  table.getDataBodyRange().format.autofitRows();

  return table;
}

function drawHeader(
  sheet: Excel.Worksheet,
): void {
  const arraySample = new Array(7);
  const range = sheet.getRange('A1:H1');
  range.format.columnWidth = 100;
  range.format.rowHeight = 50;
  range.format.verticalAlignment = 'Center';

  range.values = [['Daily Swap Data Repository'].concat(arraySample)];
  range.format.font.size = 30;
  range.format.font.color = Color.TABLE_HEADER_FONT;
  range.format.fill.color = Color.TABLE_HEADER_BACKGROUND;
  range.merge();
}

export async function createTable(
  sheetName: string,
  data: Standard[],
): Promise<Result<DailySdrSub>> {
  return Excel.run(
    { delayForCellEdit: true },
    async (ctx): Promise<Result<DailySdrSub>> => {
      const sheet = ctx.workbook.worksheets.add(sheetName);
      drawHeader(sheet);
      const table = await drawTable(sheet, data);
      sheet.activate();
      sheet.load(['id', 'name']);
      table.load('id');
      await ctx.sync();
      return {
        data: {
          sheetId: sheet.id,
          sheetName: sheet.name,
          tableId: table.id,
          isActive: true,
        },
      };
    },
  ).catch(() => ({
    error: {
      title: 'Failed to create SDR table',
      message: 'An unexpected error occurred while creating an SDR table.',
    },
  }));
}

export async function refreshTable(
  subscription: DailySdrSub,
  data: Standard[],
): Promise<Result<DailySdrSub>> {
  return Excel.run(
    { delayForCellEdit: true },
    async (ctx): Promise<Result<DailySdrSub>> => {
      const sheet = ctx.workbook.worksheets.getItem(subscription.sheetId);
      const oldTable = sheet.tables.getItem(subscription.tableId);
      await ctx.sync();
      if (sheet.isNullObject) {
        return null;
      }
      if (!oldTable.isNullObject) {
        oldTable.delete();
      }

      const table = await drawTable(sheet, data);
      sheet.load(['id', 'name']);
      table.load('id');
      await ctx.sync();
      return {
        data: {
          sheetId: sheet.id,
          sheetName: sheet.name,
          tableId: table.id,
          isActive: true,
        },
      };
    },
  ).catch(() => ({
    error: {
      title: 'Failed to refresh SDR table',
      message: 'An unexpected error occurred while refreshing an SDR table.',
    },
  }));
}

export function reconnectSubscriptions(
  userInfo: UserInfo,
  dispatch: Dispatch,
): void {
  const subscriptions: DailySdrSub[] = JSON.parse(
    Office.context.document.settings.get(SUBSCRIPTIONS),
  );
  if (subscriptions != null) {
    dispatch(DailySdrActions.connectToHub(dispatch));
    dispatch(DailySdrActions.init(subscriptions));
    subscriptions.forEach((subscription: DailySdrSub): void => {
      getSheetNameById(subscription.sheetId)
        .then((sheetName: string): void => {
          if (isNullOrEmptyString(sheetName)) {
            dispatch(DailySdrActions.unsubscribe(subscription.sheetId, dispatch));
          } else {
            if (subscription.sheetName !== sheetName) {
              dispatch(DailySdrActions.tryRenameSheet({ ...subscription, sheetName }, true));
            }
            if (subscription.isActive) {
              dispatch(DailySdrActions.refreshSubscription(
                subscription,
                userInfo,
                dispatch,
              ));
            }
          }
        });
    });
  }
}
