import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useState,
} from 'react';
import {
  IconButton,
  DatePicker,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  ResponsiveMode,
  TextField,
  Toggle,
} from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';

import { priceDirectionsOptions, priceTypesOptions, productTypesOptions } from './options';
import LoadingDialog from '../LoadingDialog';

import formatDate from '../../utils/date';

import * as HistoActions from '../../actions/histo';

import { GetHistoAction } from '../../types/Histo';
import { State } from '../../types/State';
import { UserInfo } from '../../types/UserInfo';
import LoadingStatus from '../../types/enums/LoadingStatus';

function handleMultiSelectChange(
  e: FormEvent<HTMLDivElement>,
  item: IDropdownOption,
  items: number[],
  setItems: (a: number[]) => void,
): void {
  const selectedItems = [...items];
  if (item.selected) {
    selectedItems.push(item.key as number);
  } else {
    const currIndex = selectedItems.indexOf(item.key as number);
    if (currIndex > -1) {
      selectedItems.splice(currIndex, 1);
    }
  }
  return setItems(selectedItems);
}

function createClearButton(onclick: () => void): () => React.ReactElement {
  const el = () => (
    <IconButton
      iconProps={{ iconName: 'Clear' }}
      onClick={onclick}
      styles={{
        root: { color: 'none', background: 'none' },
        rootHovered: { background: 'none' },
        rootFocused: { background: 'none' },
        rootPressed: { background: 'none' },
      }}
    />
  );
  el.displayName = 'ClearButton';
  return el;
}

const Histo: React.FunctionComponent = (): React.ReactElement => {
  const [worksheetName, setWorksheetName] = useState('');
  const [tickers, setTickers] = useState('ITXEB534');
  const [productTypes, setProductTypes] = useState<number[]>([]);
  const [priceTypes, setPriceTypes] = useState<number[]>([]);
  const [priceDirections, setPriceDirections] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(null);
  const [dealers, setDealers] = useState('');
  const [switchPrice, setSwitchPrice] = useState(true);
  const [greeks, setGreeks] = useState(false);

  const userInfos = useSelector((state: State): UserInfo => state.app.userInfo);
  const loadingStatus = useSelector((state: State): LoadingStatus => state.histo.loadingStatus);

  const dispatch = useDispatch();

  const handleHistoSubmit = useCallback(
    (): GetHistoAction => dispatch(HistoActions.getHisto(
      worksheetName,
      tickers,
      productTypes,
      priceTypes,
      priceDirections,
      startDate,
      endDate,
      dealers,
      switchPrice,
      greeks,
      userInfos,
    )),
    [
      dispatch,
      worksheetName,
      tickers,
      productTypes,
      priceTypes,
      priceDirections,
      startDate,
      endDate,
      dealers,
      switchPrice,
      greeks,
      userInfos,
    ],
  );

  return (
    <div className="container_commandsContent">
      <h1>Historical Ticker Data</h1>
      <p>
        Clicking on Download data will create a new worksheet, filled with historical data.
      </p>
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>): GetHistoAction => {
          e.preventDefault();
          return handleHistoSubmit();
        }}
      >
        <TextField
          type="text"
          label="Worksheet Name"
          value={worksheetName}
          onChange={
            (e: ChangeEvent<HTMLInputElement>): void => setWorksheetName(e.target.value)
          }
        />
        <TextField
          type="text"
          label="Ticker"
          value={tickers}
          onChange={
            (e: ChangeEvent<HTMLInputElement>): void => setTickers(e.target.value)
          }
        />
        <Dropdown
          label="Instrument type"
          responsiveMode={ResponsiveMode.large}
          multiSelect
          selectedKeys={productTypes}
          options={productTypesOptions}
          onChange={
            (e, item): void => handleMultiSelectChange(e, item, productTypes, setProductTypes)
          }
        />
        <Dropdown
          label="Price type"
          responsiveMode={ResponsiveMode.large}
          multiSelect
          selectedKeys={priceTypes}
          options={priceTypesOptions}
          onChange={
            (e, item): void => handleMultiSelectChange(e, item, priceTypes, setPriceTypes)
          }
        />
        <Dropdown
          label="Price Direction"
          responsiveMode={ResponsiveMode.large}
          multiSelect
          selectedKeys={priceDirections}
          options={priceDirectionsOptions}
          onChange={
            (e, item): void => handleMultiSelectChange(e, item, priceDirections, setPriceDirections)
          }
        />
        <DatePicker
          className="Histo_datePicker"
          label="Start Date"
          calloutProps={{ hideOverflow: true }}
          showGoToToday={false}
          isMonthPickerVisible={false}
          value={startDate}
          formatDate={(date: Date): string => formatDate(date)}
          onSelectDate={setStartDate}
          textField={{
            inputClassName: 'Histo_datePicker_inner',
            onRenderSuffix: createClearButton(() => setStartDate(null)),
            styles: { suffix: { padding: '0 4px', background: 'none' } },
          }}
          styles={{
            icon: { left: '9px', right: 'unset' },
          }}
        />
        <DatePicker
          className="Histo_datePicker"
          label="End Date"
          calloutProps={{ hideOverflow: true }}
          showGoToToday={false}
          isMonthPickerVisible={false}
          value={endDate}
          formatDate={(date: Date): string => formatDate(date)}
          onSelectDate={setEndDate}
          textField={{
            inputClassName: 'Histo_datePicker_inner',
            onRenderSuffix: createClearButton(() => setEndDate(null)),
            styles: { suffix: { padding: '0 4px', background: 'none' } },
          }}
          styles={{
            icon: { left: '9px', right: 'unset' },
          }}
        />
        <TextField
          type="text"
          label="Dealer"
          value={dealers}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => setDealers(e.target.value)}
        />
        <Toggle
          checked={switchPrice}
          onChange={(): void => setSwitchPrice(!switchPrice)}
          label="Switch when possible"
          onText="On"
          offText="Off"
        />
        <Toggle
          checked={greeks}
          onChange={(): void => setGreeks(!greeks)}
          label="Include all greeks"
          onText="On"
          offText="Off"
        />
        <PrimaryButton
          text="Insert worksheet"
          disabled={tickers == null}
          type="submit"
        />
      </form>
      <LoadingDialog
        isLoading={loadingStatus === LoadingStatus.fetching}
        title="Downloading..."
        subText="Currently loading historical data, this could take a while."
      />
      <LoadingDialog
        isLoading={loadingStatus === LoadingStatus.saving}
        title="Saving..."
        subText="Saving historical data in Excel."
      />
    </div>
  );
};

export default Histo;
