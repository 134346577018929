import { prefixMaker } from '../utils/string';

const prefix = prefixMaker('APP');

export const OFFICE_INITIALIZED = prefix('OFFICE_INITIALIZED');

export const APP_INITIALIZE = prefix('APP_INITIALIZE');

export const SET_SESSION_INFOS = prefix('SET_SESSION_INFOS');
export const LOGOUT = prefix('LOGOUT');
export const EXISTING_SHEET_DIALOG = prefix('EXISTING_SHEET_DIALOG');
export const EXISTING_SHEET_DIALOG_OPEN = prefix('EXISTING_SHEET_DIALOG_OPEN');
export const EXISTING_SHEET_DIALOG_CLOSE = prefix('EXISTING_SHEET_DIALOG_CLOSE');
export const EXISTING_SHEET_DIALOG_YES = prefix('EXISTING_SHEET_DIALOG_YES');
export const EXISTING_SHEET_DIALOG_NO = prefix('EXISTING_SHEET_DIALOG_NO');

export const ERROR_CLOSE = prefix('ERROR_CLOSE');
export const ERROR_PUSH = prefix('ERROR_PUSH');

export const ON_DELETED_EXCEL_WORKSHEET = prefix('ON_DELETED_EXCEL_WORKSHEET');
