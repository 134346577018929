import {
  takeLeading,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import * as Type from '../constants/app';
import { HUB_CALLBACK_METHOD_STANDARD } from '../constants/dailySdr';

import registerOnDeletedHandler from '../excel/initialize';
import { unsubscribe as unsubscribeDailySdr } from '../actions/dailySdr';
import { reconnectSubscriptions as reconnectDailySdr } from '../excel/dailySdr';
import * as RealtimeTickerActions from '../actions/realtimeTicker';
import { reconnectSubscriptions as reconnectRealtimeTicker } from '../excel/realtimeTicker';
import * as Hub from '../utils/signalR';

import { OnDeleteExcelWorkSheetAction, InitializeAction } from '../types/App';

function* handleUserDeleteSheet(
  action: OnDeleteExcelWorkSheetAction,
): SagaIterator {
  const { sheetId, dispatch } = action.payload;
  yield put(unsubscribeDailySdr(sheetId, dispatch));
  yield put(RealtimeTickerActions.unsubscribe(dispatch, sheetId));
}

function* initializeApp(action: InitializeAction): SagaIterator {
  const { userInfo, dispatch } = action.payload;
  yield call(reconnectRealtimeTicker, dispatch);
  yield put(RealtimeTickerActions.startLoop(dispatch, userInfo));
  yield call(reconnectDailySdr, userInfo, dispatch);
  yield call(registerOnDeletedHandler, dispatch);
}

function* logout(): SagaIterator {
  yield call(Hub.off, HUB_CALLBACK_METHOD_STANDARD);
  yield put(RealtimeTickerActions.stopLoop);
}

export default function* watchInit(): SagaIterator {
  yield takeLeading(Type.APP_INITIALIZE, initializeApp);
  yield takeLeading(Type.LOGOUT, logout);
  yield takeEvery(Type.ON_DELETED_EXCEL_WORKSHEET, handleUserDeleteSheet);
}
