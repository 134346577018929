import { StateRealtimeTicker } from '../types/State';
import LoadingStatus from '../types/enums/LoadingStatus';

import createReducer from '../utils/createReducer';
import * as Type from '../constants/realtimeTicker';
import {
  RealtimeTickerSub,
  StoreSubscriptionAction,
  UnsubscribeAction,
  UpdateSubscriptionAction,
  InitAction,
} from '../types/RealtimeTicker';

const initialState: StateRealtimeTicker = {
  loadingStatus: LoadingStatus.default,
  subscriptions: [],
  isRealtimeActive: false,
};

export default createReducer(initialState, {
  [Type.INIT](
    state: StateRealtimeTicker,
    action: InitAction,
  ): StateRealtimeTicker {
    const subscriptions = action.payload;
    return {
      ...state,
      subscriptions,
    };
  },
  [Type.START_LOOP](
    state: StateRealtimeTicker,
  ): StateRealtimeTicker {
    return {
      ...state,
      isRealtimeActive: true,
    };
  },
  [Type.STOP_LOOP](
    state: StateRealtimeTicker,
  ): StateRealtimeTicker {
    return {
      ...state,
      isRealtimeActive: false,
    };
  },
  [Type.STATUS_FETCHING](
    state: StateRealtimeTicker,
  ): StateRealtimeTicker {
    return {
      ...state,
      loadingStatus: LoadingStatus.fetching,
    };
  },
  [Type.STATUS_SAVING](
    state: StateRealtimeTicker,
  ): StateRealtimeTicker {
    return {
      ...state,
      loadingStatus: LoadingStatus.saving,
    };
  },
  [Type.STATUS_DEFAULT](
    state: StateRealtimeTicker,
  ): StateRealtimeTicker {
    return {
      ...state,
      loadingStatus: LoadingStatus.default,
    };
  },
  [Type.STORE_SUBSCRIPTION](
    state: StateRealtimeTicker,
    action: StoreSubscriptionAction,
  ): StateRealtimeTicker {
    const sub = action.payload;
    return {
      ...state,
      subscriptions: [...state.subscriptions, sub],
    };
  },
  [Type.UNSUBSCRIBE](
    state: StateRealtimeTicker,
    action: UnsubscribeAction,
  ): StateRealtimeTicker {
    const { sheetId, rangeAddress } = action.payload;
    const subscriptions = state.subscriptions.filter(
      (sub: RealtimeTickerSub): boolean => (
        !(sheetId === sub.sheetId && rangeAddress == null)
        && !(sheetId === sub.sheetId && rangeAddress !== null && rangeAddress === sub.rangeAddress)
      ),
    );
    return {
      ...state,
      subscriptions,
    };
  },
  [Type.UPDATE_SUBSCRIPTION](
    state: StateRealtimeTicker,
    action: UpdateSubscriptionAction,
  ): StateRealtimeTicker {
    const sub = action.payload;
    const newSubs = state.subscriptions.map((s) => {
      if (s.sheetId !== sub.sheetId || s.rangeAddress !== sub.rangeAddress) {
        return s;
      }
      return { ...sub };
    });

    return {
      ...state,
      subscriptions: newSubs,
    };
  },
});
