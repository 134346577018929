import React, {
  FunctionComponent,
  ReactElement,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '@fluentui/react/lib/Icon';

import * as RealtimeTickerActions from '../../actions/realtimeTicker';

import { State } from '../../types/State';
import { UserInfo } from '../../types/UserInfo';
import { getFullRangeAddress } from '../../utils/helpers';
import { RealtimeTickerSub } from '../../types/RealtimeTicker';

const RangeDashBoard: FunctionComponent = (): React.ReactElement => {
  const realtimeTickerSubs: RealtimeTickerSub[] = useSelector(
    (state: State): RealtimeTickerSub[] => state.realtimeTicker.subscriptions,
  );
  const userInfo = useSelector((state: State): UserInfo => state.app.userInfo);

  const dispatch = useDispatch();

  return realtimeTickerSubs.length > 0 && (
    <div className="card card-bordered">
      <div>
        { realtimeTickerSubs.map((sub): ReactElement => {
          const {
            sheetId,
            sheetName,
            rangeAddress,
            isActive,
          } = sub;
          return (
            <div
              key={getFullRangeAddress(sub)}
              className="SheetDashboard_container"
            >
              <span>
                <Icon
                  iconName="ExcelDocument"
                />
                {` ${sheetName}`}
              </span>
              <span>
                {` ${rangeAddress}`}
              </span>
              <span>
                <Icon
                  iconName={isActive ? 'PlugConnected' : 'PlugDisconnected'}
                  className={`SheetDashboard_icon ${isActive ? 'text-success' : 'text-error'}`}
                  onClick={
                    (): void => {
                      dispatch(RealtimeTickerActions.toggleSubscription(
                        dispatch,
                        userInfo,
                        sub.sheetId,
                        sub.rangeAddress,
                      ));
                    }
                  }
                />
                <Icon
                  iconName="Delete"
                  className="SheetDashboard_icon text-error"
                  onClick={
                    (): void => {
                      dispatch(RealtimeTickerActions.unsubscribe(dispatch, sheetId, rangeAddress));
                    }
                  }
                />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RangeDashBoard;
