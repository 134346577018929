const DAY_MILLISECONDS = 86400000;
const MS_DAY_OFFSET = 25569;

export default function formatDate(date: Date, end = false): string {
  let dateToParse = date;
  if (end) {
    dateToParse = new Date(date.getTime() + DAY_MILLISECONDS);
  }

  const y = dateToParse.getFullYear();
  const m = (dateToParse.getMonth() + 1).toString().padStart(2, '0');
  const d = dateToParse.getDate().toString().padStart(2, '0');
  return `${y}-${m}-${d}`;
}

export function parseDate(date: string | Date, isUtc = true): Date {
  if (typeof date === 'string') {
    const dateToParse = (isUtc && date[date.length - 1] !== 'Z') ? `${date}Z` : date;
    return new Date(dateToParse);
  }
  return date;
}

export function maxDate(date1: Date, date2: Date): Date {
  if (date1 == null) {
    return date2;
  }
  if (date2 == null) {
    return date1;
  }
  if (date1 > date2) {
    return date1;
  }
  return date2;
}

export function dateToOADate(date: Date): number {
  // we need to correct getTime (which is the number of ms since epoch in the UTC timezone) with
  // the delta between UTC and the current timezone in ms
  const dateInMs = date.getTime() - (date.getTimezoneOffset() * 60 * 1000);
  let oad = (dateInMs / DAY_MILLISECONDS) + MS_DAY_OFFSET;
  if (oad < 0) {
    const frac = oad - Math.trunc(oad);
    if (frac !== 0) {
      oad = Math.ceil(oad) - frac - 2;
    }
  }
  return oad;
}
