import {
  all,
  Effect,
} from 'redux-saga/effects';

import watchAuthDialog from './auth';
import watchHisto from './histo';
import watchDailySdr from './dailySdr';
import watchInit from './initialize';
import watchRealtimeTicker from './realtimeTicker';

export default function* rootSaga():
IterableIterator<Effect<'ALL'>> {
  yield all([
    watchAuthDialog(),
    watchHisto(),
    watchDailySdr(),
    watchInit(),
    watchRealtimeTicker(),
  ]);
}
