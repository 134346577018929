import { Action } from '../types/Action';

export default function createReducer<T>(
  initialState: T,
  handlers: { [key: string]: (state: T, action: Action) => T},
): (state: T, action: Action) => T {
  return (state = initialState, action): T => (handlers[action.type]
    ? handlers[action.type](state, action)
    : state
  );
}
