import { Dispatch } from 'redux';

import * as Type from '../constants/realtimeTicker';

import { UserInfo } from '../types/UserInfo';
import {
  RealtimeTickerSub,
  UnsubscribeAction,
  UpdateSubscriptionAction,
  StoreSubscriptionAction,
  ToggleAction,
  SubscribeAction,
  InitAction,
  StartLoopAction,
  LoopAction,
} from '../types/RealtimeTicker';
import { Action } from '../types/Action';

export const init = (
  subscriptions: RealtimeTickerSub[],
): InitAction => ({
  type: Type.INIT,
  payload: subscriptions,
});

export const startLoop = (
  dispatch: Dispatch,
  userInfo: UserInfo,
): StartLoopAction => ({
  type: Type.START_LOOP,
  payload: {
    userInfo,
    dispatch,
  },
});

export const loop = (
  dispatch: Dispatch,
  userInfo: UserInfo,
): LoopAction => ({
  type: Type.LOOP,
  payload: {
    userInfo,
    dispatch,
  },
});

export const stopLoop: Action = {
  type: Type.STOP_LOOP,
};

export const setStatusToDefault: Action = {
  type: Type.STATUS_DEFAULT,
};

export const setStatusToFetching: Action = {
  type: Type.STATUS_FETCHING,
};

export const setStatusToSaving: Action = {
  type: Type.STATUS_SAVING,
};

export const subscribe = (
  hasHeaders: boolean,
  userInfo: UserInfo,
  dispatch: Dispatch,
): SubscribeAction => ({
  type: Type.SUBSCRIBE,
  payload: {
    dispatch,
    hasHeaders,
    userInfo,
  },
});

export const storeSubscription = (sub: RealtimeTickerSub): StoreSubscriptionAction => ({
  type: Type.STORE_SUBSCRIPTION,
  payload: sub,
});

export const updateSubscription = (sub: RealtimeTickerSub): UpdateSubscriptionAction => ({
  type: Type.UPDATE_SUBSCRIPTION,
  payload: sub,
});

export const unsubscribe = (
  dispatch: Dispatch,
  sheetId: string,
  rangeAddress?: string,
): UnsubscribeAction => ({
  type: Type.UNSUBSCRIBE,
  payload: {
    dispatch,
    sheetId,
    rangeAddress,
  },
});

export const toggleSubscription = (
  dispatch: Dispatch,
  userInfo: UserInfo,
  sheetId: string,
  rangeAddress: string,
): ToggleAction => ({
  type: Type.TOGGLE,
  payload: {
    dispatch,
    userInfo,
    sheetId,
    rangeAddress,
  },
});
