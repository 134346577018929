import { Result, ErrorResult } from '../types/Result';
import { RealtimeTickerSub } from '../types/RealtimeTicker';

export function isNullOrEmptyString(s: string): boolean {
  return s == null || s === '';
}

export function isNullOrEmptyObject<T>(o: {[s: string]: T}): boolean {
  return o == null || (Object.keys(o).length === 0 && o.constructor === Object);
}

function parseCellAddress(address: string): {
  address: string;
  column: string,
  row: number,
} {
  if (isNullOrEmptyString(address)) {
    return null;
  }

  const cellParam: { address: string; column: string; row: number; } = {
    address,
    column: null,
    row: null,
  };
  const [column, row] = address.match(/[a-z]+|[^a-z]+/gi);

  if (row == null && !Number.isNaN(Number(column))) {
    cellParam.row = Number(column);
  } else {
    cellParam.row = Number(row);
    cellParam.column = column;
  }

  return cellParam;
}

export function getRangeInfoFromAddress(address: string): {
  sheetName: string;
  rangeAddress: string;
  startCell: {
    address: string;
    column: string;
    row: number;
  },
  endCell: {
    address: string;
    column: string;
    row: number;
  }
} {
  if (isNullOrEmptyString(address)) {
    return null;
  }
  let [sheet, range] = address.split('!');
  if (range == null) {
    range = sheet;
    sheet = null;
  }
  const [startCellAddr, endCellAddr] = range.split(':');

  return {
    sheetName: sheet,
    rangeAddress: range,
    startCell: parseCellAddress(startCellAddr),
    endCell: parseCellAddress(endCellAddr),
  };
}

export function isResultError<T>(result: Result<T>): result is ErrorResult {
  return (result as ErrorResult).error !== undefined;
}

export function getFullRangeAddress(realtimeTickerSub: RealtimeTickerSub): string {
  return `${realtimeTickerSub.sheetName}!${realtimeTickerSub.rangeAddress}`;
}
