import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dropdown,
  IDropdownOption as DropdownOption,
  Link,
  PrimaryButton,
  ResponsiveMode,
  TextField,
} from '@fluentui/react';

import { isNullOrEmptyString } from '../../utils/helpers';
import { getAppUrl } from '../../utils/env';

import Header from '../Header';
import Footer from '../Footer';
import Progress from '../Progress';

import * as AuthActions from '../../actions/auth';

import { State, StateApp, StateAuth } from '../../types/State';
import { LoginAction } from '../../types/Auth';
import InstanceTypes from '../../types/enums/InstanceTypes';

interface AuthDispatch {
  handleLoginClick: (
    e: string,
    p: string,
    en: InstanceTypes,
    a: string,
  ) => LoginAction;
}

type IAuthProps = StateApp & StateAuth & AuthDispatch;

const instanceOptions: DropdownOption[] = [
  { key: InstanceTypes.Public, text: InstanceTypes.Public },
  { key: InstanceTypes.Premium, text: InstanceTypes.Premium },
  { key: InstanceTypes.Private, text: InstanceTypes.Private },
];

const Auth: FunctionComponent<IAuthProps> = (): React.ReactElement => {
  const [env, setEnv] = useState<InstanceTypes>(InstanceTypes.Public);
  const [appEnv, setAppEnv] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [instanceNameLocked, setInstanceNameLocked] = useState(true);
  const [loginLocked, setLoginLocked] = useState(true);

  const [authError, setAuthError] = useState<string>(null);

  const isOfficeInitialized = useSelector(
    (state: State): boolean => state.app.isOfficeInitialized,
  );
  const loginError = useSelector(
    (state: State): string => state.auth.loginError,
  );

  const dispatch = useDispatch();

  useEffect((): void => {
    if (loginError == null) {
      setAuthError(null);
    } else {
      setAuthError(loginError);
    }
  }, [loginError]);

  useEffect((): void => {
    setInstanceNameLocked(env !== InstanceTypes.Private);
    setAppEnv('');
  }, [env]);

  useEffect((): void => {
    setLoginLocked((isNullOrEmptyString(email) || isNullOrEmptyString(password)));
  }, [email, password]);

  if (!isOfficeInitialized) {
    return (
      <Progress />
    );
  }

  return (
    <div className="container_app">
      <div className="container_content">
        <Header />
        <div className="container">
          <div className="card ms-depth-8">
            <h1>Excel add-in</h1>
            <form
              onSubmit={(e: FormEvent<HTMLFormElement>): LoginAction => {
                e.preventDefault();
                return dispatch(AuthActions.login(email, password, env, appEnv));
              }}
            >
              <Dropdown
                className="Auth_input"
                label="Instance"
                responsiveMode={ResponsiveMode.large}
                selectedKey={env}
                options={instanceOptions}
                onChange={(e: FormEvent<HTMLDivElement>, option: DropdownOption):
                void => setEnv(option.key as InstanceTypes)}
              />
              <TextField
                className="Auth_input"
                placeholder="Private instance name"
                type="text"
                disabled={instanceNameLocked}
                value={appEnv}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>): void => setAppEnv(e.target.value)
                }
              />
              <TextField
                className={`Auth_input ${authError ? 'Auth_input-error' : ''}`}
                placeholder="Email"
                value={email}
                errorMessage={authError}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => setEmail(e.target.value)}
              />
              <TextField
                className={`Auth_input ${authError ? 'Auth_input-error' : ''}`}
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => setPassword(e.target.value)}
              />
              <div className="container_btn">
                <PrimaryButton
                  text="Sign in"
                  type="submit"
                  disabled={loginLocked}
                />
              </div>
            </form>
            <div className="Auth_link">
              <Link
                href={`${getAppUrl(env, appEnv)}/auth`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {'Don\'t have an account ?'}
              </Link>
              <Link
                href={`${getAppUrl(env, appEnv)}/auth/forgot_password`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Forgot your password ?
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Auth;
