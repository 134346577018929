import createReducer from '../utils/createReducer';

import * as Type from '../constants/app';

import { StateApp } from '../types/State';
import { SetSessionInfoAction, ErrorPushAction } from '../types/App';

const initialState: StateApp = {
  isOfficeInitialized: false,
  userInfo: {},
  errors: [],
  displayExistingSheetDialog: false,
};

export default createReducer(initialState, {
  [Type.OFFICE_INITIALIZED](state: StateApp): StateApp {
    return {
      ...state,
      isOfficeInitialized: true,
    };
  },

  [Type.SET_SESSION_INFOS](state: StateApp, action: SetSessionInfoAction): StateApp {
    const {
      userName,
      token,
      env,
      appEnv,
    } = action.payload;
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        userName,
        token,
        env,
        appEnv,
      },
    };
  },

  [Type.LOGOUT](state: StateApp): StateApp {
    return {
      ...state,
      userInfo: {},
    };
  },

  [Type.EXISTING_SHEET_DIALOG_OPEN](state: StateApp): StateApp {
    return {
      ...state,
      displayExistingSheetDialog: true,
    };
  },

  [Type.EXISTING_SHEET_DIALOG_CLOSE](state: StateApp): StateApp {
    return {
      ...state,
      displayExistingSheetDialog: false,
    };
  },

  [Type.ERROR_CLOSE](state: StateApp): StateApp {
    const nextState = [...state.errors];
    nextState.pop();
    return {
      ...state,
      errors: nextState,
    };
  },

  [Type.ERROR_PUSH](state: StateApp, action: ErrorPushAction): StateApp {
    const nextState = [...state.errors];
    nextState.push(action.payload);
    return {
      ...state,
      errors: nextState,
    };
  },
});
