import * as signalR from '@microsoft/signalr';
import { getHomePageUrl } from './env';

let connection: signalR.HubConnection;

export const defineConnection = (
  appEnv: string,
): void => {
  connection = new signalR.HubConnectionBuilder()
    .withUrl(`${getHomePageUrl(appEnv)}/realtime`)
    .build();
};

export async function start(): Promise<void> {
  if (connection.state === signalR.HubConnectionState.Disconnected) {
    try {
      await connection.start();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setTimeout((): Promise<void> => start(), 5000);
    }
  }
}

export function on<T>(string: string, cb: (value: T) => void): void {
  connection.on(string, cb);
}

export function off(string: string): void {
  connection.off(string);
}

export function bindConnectionOnClose(): void {
  connection.onclose(async (): Promise<void> => {
    await start();
  });
}
