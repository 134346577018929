import * as React from 'react';
import { NavLink } from 'react-router-dom';

const PivotNav: React.FunctionComponent = (): React.ReactElement => (
  <nav className="PivotNav">
    <div className="PivotNav_tab">
      <NavLink
        activeClassName="selected"
        to="/ticker"
      >
        Ticker
      </NavLink>
    </div>
    <div className="PivotNav_tab">
      <NavLink
        activeClassName="selected"
        to="/dailySdr"
      >
        SDR
      </NavLink>
    </div>
    <div className="PivotNav_tab">
      <NavLink
        activeClassName="selected"
        to="/histo"
      >
        Histo
      </NavLink>
    </div>
  </nav>
);

export default PivotNav;
