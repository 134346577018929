import { decamelizeCap } from '../utils/string';
import { dateToOADate, parseDate } from '../utils/date';

import * as Format from '../constants/format';
import * as Color from '../constants/color';

import { Tick } from '../types/Tick';
import { Result } from '../types/Result';

interface Column {
  name: string;
  format?: string;
}

interface ColumnLayout {
  columns: Column[];
  tickMapper: (tick: Tick) => (string | number)[],
}

const getLetterFromNumber = (n: number): string => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  return alphabet[n].toUpperCase();
};

const columnLayoutNormal: ColumnLayout = {
  columns: [
    { name: 'Time', format: Format.DATE },
    { name: 'Ticker' },
    { name: 'Price Type' },
    { name: 'Price Direction' },
    { name: 'Standard Level', format: Format.NUMBER },
    { name: 'Upfront', format: Format.PERCENTAGE },
    { name: 'Cash Price', format: Format.PERCENTAGE },
    { name: 'Size (M)', format: Format.NUMBER },
    { name: 'Dealer' },
  ],
  tickMapper: (row) => [
    dateToOADate(parseDate(row.valueDate)),
    row.ticker,
    decamelizeCap(row.priceType),
    row.priceDirection,
    row.standardLevel,
    row.upfront / 100,
    row.price / 100,
    row.size == null ? null : row.size / 1_000_000,
    row.firm,
  ],
};

const columnLayoutGreeks: ColumnLayout = {
  columns: [
    { name: 'Time', format: Format.DATE },
    { name: 'Ticker' },
    { name: 'Price Type' },
    { name: 'Price Direction' },
    { name: 'Standard Level', format: Format.NUMBER },
    { name: 'Upfront', format: Format.PERCENTAGE },
    { name: 'Cash Price', format: Format.PERCENTAGE },
    { name: 'Delta', format: Format.NUMBER },
    { name: 'Theta', format: Format.NUMBER },
    { name: 'Vega', format: Format.NUMBER },
    { name: 'Gamma', format: Format.NUMBER },
    { name: 'Gamma 1%', format: Format.NUMBER },
    { name: 'CS01', format: Format.NUMBER },
    { name: 'Reference Standard Level', format: Format.NUMBER },
    { name: 'Reference CS01', format: Format.NUMBER },
    { name: 'Size (M)', format: Format.NUMBER },
    { name: 'Dealer' },
  ],
  tickMapper: (row) => [
    dateToOADate(parseDate(row.valueDate)),
    row.ticker,
    decamelizeCap(row.priceType),
    row.priceDirection,
    row.standardLevel,
    row.upfront / 100,
    row.price / 100,
    row.delta,
    row.theta,
    row.vega,
    row.gamma,
    row.gamma1pct,
    row.cs01,
    row.referenceStandardLevel,
    row.referenceCS01,
    row.size == null ? null : row.size / 1_000_000,
    row.firm,
  ],
};

function drawTable(
  sheet: Excel.Worksheet,
  columnLayout: ColumnLayout,
  data: Tick[],
): void {
  const columnNames: (string | number)[][] = [
    columnLayout.columns.map((column) => column.name),
  ];
  let rows = data.map(columnLayout.tickMapper);
  rows = columnNames.concat(rows);

  const tableRange = `A3:${getLetterFromNumber(columnLayout.columns.length - 1)}${rows.length + 2}`;
  const dataRange = sheet.getRange(tableRange);
  dataRange.values = rows;
  dataRange.untrack();

  const table = sheet.tables.add(tableRange, true);

  table.sort.apply([
    {
      key: 0,
      ascending: false,
    },
  ]);

  columnLayout.columns.forEach((column) => {
    if (column.format != null) {
      table.columns.getItem(column.name).getDataBodyRange().numberFormat = [[column.format]];
    }
  });
  table.getDataBodyRange().format.autofitColumns();
  table.getDataBodyRange().format.autofitRows();
}

function drawHeader(
  sheet: Excel.Worksheet,
  columnLayout: ColumnLayout,
  ticker: string,
): void {
  const arraySample = new Array(columnLayout.columns.length - 1);
  const lastColumn = getLetterFromNumber(columnLayout.columns.length - 1);
  let range = sheet.getRange(`A1:${lastColumn}2`);
  range.format.columnWidth = 100;
  range.format.rowHeight = 50;
  range.format.verticalAlignment = 'Center';

  range = sheet.getRange(`A1:${lastColumn}1`);
  range.values = [['Historical Data'].concat(arraySample)];
  range.format.font.size = 30;
  range.format.font.color = Color.TABLE_HEADER_FONT;
  range.format.fill.color = Color.TABLE_HEADER_BACKGROUND;
  range.merge();

  range = sheet.getRange(`A2:${lastColumn}2`);
  range.values = [[`Ticker : ${ticker}`].concat(arraySample)];
  range.format.font.size = 14;
  range.format.rowHeight = 30;
  range.merge();
}

export default async function display(
  sheetName: string,
  tickers: string,
  hasGreeks: boolean,
  data: Tick[],
): Promise<Result<null>> {
  return Excel.run(
    { delayForCellEdit: true },
    async (ctx): Promise<Result<null>> => {
      const sheet = ctx.workbook.worksheets.add(sheetName);
      const columnLayout = hasGreeks
        ? columnLayoutGreeks
        : columnLayoutNormal;
      drawHeader(sheet, columnLayout, tickers);
      drawTable(sheet, columnLayout, data);
      sheet.activate();
      await ctx.sync();
      return { data: null };
    },
  ).catch(() => {
    const error = {
      title: 'Historical Ticker Data display failed',
      message: 'Check if you are in cell-editing mode and retry.',
    };
    return { error };
  });
}
