import { Dispatch } from 'redux';
import { Standard } from '@ht/otcstreaming';

import * as Type from '../constants/dailySdr';

import { Action } from '../types/Action';
import { UserInfo } from '../types/UserInfo';
import {
  DailySdrSub,
  AddStandardAction,
  InitAction,
  ToggleAction,
  ConnectHubAction,
  SubscribeAction,
  StoreSubscribeAction,
  UnsubscribeAction,
  RefreshAction,
  RenameSheetAction,
  UpdateSubscriptionAction,
} from '../types/DailySdr';

export const init = (
  sheetsSubscription: DailySdrSub[],
): InitAction => ({
  type: Type.INIT,
  payload: sheetsSubscription,
});

export const setStatusToDefault: Action = {
  type: Type.STATUS_DEFAULT,
};

export const setStatusToFetching: Action = {
  type: Type.STATUS_FETCHING,
};

export const setStatusToSaving: Action = {
  type: Type.STATUS_SAVING,
};

export const connectToHub = (
  dispatch: Dispatch,
): ConnectHubAction => ({
  type: Type.HUB_CONNECT,
  payload: {
    dispatch,
  },
});

export const hubConnected: Action = {
  type: Type.HUB_CONNECTED,
};

export const subscribe = (
  sheetName: string,
  isStarted: boolean,
  userInfo: UserInfo,
  dispatch: Dispatch,
): SubscribeAction => ({
  type: Type.SUBSCRIBE,
  payload: {
    data: {
      sheetName,
      isStarted,
      dispatch,
    },
    userInfo,
  },
});

export const refreshSubscription = (
  sub: DailySdrSub,
  userInfo: UserInfo,
  dispatch: Dispatch,
): RefreshAction => ({
  type: Type.REFRESH,
  payload: {
    data: {
      sub,
      dispatch,
    },
    userInfo,
  },
});

export const updateSubscription = (sub: DailySdrSub): UpdateSubscriptionAction => ({
  type: Type.UPDATE_SUBSCRIPTION,
  payload: sub,
});

export const storeSubscription = (sub: DailySdrSub): StoreSubscribeAction => ({
  type: Type.STORE_SUBSCRIPTION,
  payload: sub,
});

export const unsubscribe = (
  sheetId: string,
  dispatch: Dispatch,
): UnsubscribeAction => ({
  type: Type.UNSUBSCRIBE,
  payload: {
    sheetId,
    dispatch,
  },
});

export const tryRenameSheet = (
  sub: DailySdrSub,
  shouldRename: boolean,
): RenameSheetAction => ({
  type: Type.RENAME_SHEET,
  payload: {
    sub,
    shouldRename,
  },
});

export const toggleSubscription = (
  sheetId: string,
  userInfo: UserInfo,
  dispatch: Dispatch,
): ToggleAction => ({
  type: Type.TOGGLE,
  payload: {
    sheetId,
    userInfo,
    dispatch,
  },
});

export const addStandard = (
  sds: Standard,
  dispatch: Dispatch,
): AddStandardAction => ({
  type: Type.ON_STANDARD,
  payload: {
    sds,
    dispatch,
  },
});
