import { PriceType, PriceDirection, ProductType } from '@ht/otcstreaming';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const enumToArray = (e: any): { key: number; text: string; }[] => Object.keys(e)
  .filter((key) => Number.isNaN(Number(key)))
  .map((key) => ({ key: e[key], text: key }));

export const priceTypesOptions = enumToArray(PriceType);

export const productTypesOptions = enumToArray(ProductType);

export const priceDirectionsOptions = enumToArray(PriceDirection);
