import React, { FunctionComponent, ReactElement } from 'react';

const Footer: FunctionComponent = (): ReactElement => (
  <footer>
    <div className="footer_links">
      <a
        className="footer_link"
        href="https://otcstreaming.com"
        target="_blank"
        rel="noreferrer noopener"
      >
        HomePage
      </a>
      <i className="footer_icon ms-Icon ms-Icon--ToggleThumb" />
      <a
        className="footer_link"
        href="https://otcstreaming.com/terms"
        target="_blank"
        rel="noreferrer noopener"
      >
        Terms & Conditions
      </a>
      <i className="footer_icon ms-Icon ms-Icon--ToggleThumb" />
      <a
        className="footer_link"
        href="https://otcstreaming.com/addin-privacy"
        target="_blank"
        rel="noreferrer noopener"
      >
        Privacy
      </a>
    </div>
  </footer>
);

export default Footer;
