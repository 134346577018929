import {
  Standard,
  Price,
  SwitchStatus,
  PriceFormat,
} from '@ht/otcstreaming';

function getPrice(standard: Standard, priceFormat: string): number {
  // TODO: remove cast and use true enum when the serialization on the server is changed from
  // enum to string to enum to integer
  if (standard == null || standard.prices == null) {
    return null;
  }

  let result;
  result = standard.prices.find((price: Price): boolean => (
    (price.format as unknown) === priceFormat
    && (price.switchStatus as unknown) === SwitchStatus[SwitchStatus.Switch]));
  if (result == null) {
    result = standard.prices.find((price: Price): boolean => (
      (price.format as unknown) === priceFormat
      && (price.switchStatus as unknown) === SwitchStatus[SwitchStatus.Absolute]));
  }
  return result != null ? result.value : null;
}

export function getSpread(standard: Standard): number {
  return getPrice(standard, PriceFormat[PriceFormat.Spread]);
}

export function getUpfront(standard: Standard): number {
  return getPrice(standard, PriceFormat[PriceFormat.Upfront]) / 100;
}
