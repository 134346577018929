import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import histo from './histo';
import dailySdr from './dailySdr';
import realtimeTicker from './realtimeTicker';

export default combineReducers({
  app,
  auth,
  histo,
  dailySdr,
  realtimeTicker,
});
