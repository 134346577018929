import {
  all,
  takeLeading,
  call,
  put,
  select,
  race,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Dispatch } from 'redux';
import { Standard } from '@ht/otcstreaming';

import * as Type from '../constants/dailySdr';
import * as AppType from '../constants/app';
import * as AppActions from '../actions/app';
import * as DailySdrActions from '../actions/dailySdr';
import * as Hub from '../utils/signalR';
import { isNullOrEmptyString, isResultError } from '../utils/helpers';
import api from '../utils/fetch';
import { sheetExist, deleteSheet } from '../excel/workSheets';
import * as DailySdrExcel from '../excel/dailySdr';

import { UserInfo } from '../types/UserInfo';
import { Result } from '../types/Result';
import {
  AddStandardAction,
  ConnectHubAction,
  RefreshAction,
  SubscribeAction,
  DailySdrSub,
  DailySdrUpdateResult,
  ToggleAction,
  UnsubscribeAction,
} from '../types/DailySdr';
import { State } from '../types/State';

function* addStandard(action: AddStandardAction): SagaIterator {
  const { sds, dispatch } = action.payload;
  const subscriptions: DailySdrSub[] = yield select(
    (state: State): DailySdrSub[] => state.dailySdr.subscriptions,
  );
  const results: Result<DailySdrUpdateResult>[] = yield all(subscriptions
    .filter((sub) => sub.isActive)
    .map((sub) => call(
      DailySdrExcel.updateTable,
      sds,
      sub,
      dispatch,
    )));
  yield all(results.map((r) => {
    if (isResultError(r)) {
      return put(AppActions.errorPush(r.error));
    }
    if (r.data.isSheetDeleted) {
      return put(DailySdrActions.unsubscribe(r.data.subscription.sheetId, dispatch));
    }
    return put(DailySdrActions.tryRenameSheet(
      r.data.subscription,
      r.data.isSheetRenamed,
    ));
  }));
}

function* connectToHub(action: ConnectHubAction): SagaIterator {
  const { dispatch } = action.payload;
  yield call(
    Hub.on,
    Type.HUB_CALLBACK_METHOD_STANDARD,
    (sds: Standard): AddStandardAction => (
      dispatch(DailySdrActions.addStandard(sds, dispatch))),
  );
  yield call(Hub.start);
  yield put(DailySdrActions.hubConnected);
}

function* subscribe(action: SubscribeAction): SagaIterator {
  const { data, userInfo } = action.payload;
  const {
    isStarted,
    sheetName: worksheetName,
    dispatch,
  } = data;

  if (!isStarted) {
    yield put(DailySdrActions.connectToHub(dispatch));
  }

  const sheetName = isNullOrEmptyString(worksheetName) ? 'DailySDR' : worksheetName;
  const exist = yield call(sheetExist, sheetName);

  let isWritable = true;

  if (exist) {
    yield put(AppActions.existingSheetDialogOpen);
    const { yes } = yield race({
      yes: take(AppType.EXISTING_SHEET_DIALOG_YES),
      no: take(AppType.EXISTING_SHEET_DIALOG_NO),
    });
    isWritable = !!yes;
    if (isWritable) {
      yield call(deleteSheet, sheetName);
    }
  }

  if (isWritable) {
    yield put(DailySdrActions.setStatusToFetching);
    const response: Result<Standard[]> = yield call(api.dailySdr, userInfo);
    if (!isResultError(response)) {
      const result: Result<DailySdrSub> = yield call(
        DailySdrExcel.createTable,
        sheetName,
        response.data,
      );
      if (!isResultError(result)) {
        const sub = result.data;
        yield put(DailySdrActions.storeSubscription(sub));
        yield put(DailySdrActions.setStatusToSaving);
        yield call(DailySdrExcel.saveSubscription, sub, dispatch);
      } else {
        yield put(AppActions.errorPush(result.error));
      }
    } else {
      yield put(AppActions.errorPush(response.error));
    }
    yield put(DailySdrActions.setStatusToDefault);
  }
}

function* refreshData(
  userInfo: UserInfo,
  dispatch: Dispatch,
  sub: DailySdrSub,
): SagaIterator {
  yield put(DailySdrActions.setStatusToFetching);
  const response: Result<Standard[]> = yield call(api.dailySdr, userInfo);
  if (!isResultError(response)) {
    const result: Result<DailySdrSub> = yield call(DailySdrExcel.refreshTable, sub, response.data);
    if (!isResultError(result)) {
      const newSub = result.data;
      yield put(DailySdrActions.updateSubscription(newSub));
      yield put(DailySdrActions.setStatusToSaving);
      yield call(DailySdrExcel.updateSubscription, newSub, dispatch);
    } else {
      yield put(AppActions.errorPush(result.error));
    }
  } else {
    yield put(AppActions.errorPush(response.error));
  }
  yield put(DailySdrActions.setStatusToDefault);
}

function* refreshSubscription(action: RefreshAction): SagaIterator {
  const { data, userInfo } = action.payload;
  const { sub, dispatch } = data;
  yield call(refreshData, userInfo, dispatch, sub);
}

function* toggleSubscription(action: ToggleAction): SagaIterator {
  const { sheetId, userInfo, dispatch } = action.payload;
  const subscriptions: DailySdrSub[] = yield select(
    (state: State): DailySdrSub[] => state.dailySdr.subscriptions,
  );
  const sub = subscriptions.filter((s) => s.sheetId === sheetId)[0];
  sub.isActive = !sub.isActive;
  yield call(DailySdrExcel.updateSubscription, sub, dispatch);
  yield put(DailySdrActions.updateSubscription(sub));
  if (sub.isActive) {
    yield call(refreshData, userInfo, dispatch, sub);
  }
}

function* unsubscribe(action: UnsubscribeAction): SagaIterator {
  const { sheetId, dispatch } = action.payload;
  yield call(DailySdrExcel.removeSubscription, sheetId, dispatch);
}

export default function* watchDailySdr(): SagaIterator {
  yield takeLeading(Type.HUB_CONNECT, connectToHub);
  yield takeLeading(Type.SUBSCRIBE, subscribe);
  yield takeEvery(Type.REFRESH, refreshSubscription);
  yield takeEvery(Type.ON_STANDARD, addStandard);
  yield takeEvery(Type.TOGGLE, toggleSubscription);
  yield takeEvery(Type.UNSUBSCRIBE, unsubscribe);
}
