import {
  call,
  takeLeading,
  put,
  race,
  take,
} from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import * as AppActions from '../actions/app';
import * as HistoActions from '../actions/histo';
import displayHistoExcel from '../excel/histo';

import * as Type from '../constants/histo';
import * as AppType from '../constants/app';

import api from '../utils/fetch';
import { isNullOrEmptyString, isResultError } from '../utils/helpers';
import { deleteSheet, sheetExist } from '../excel/workSheets';

import { GetHistoAction } from '../types/Histo';
import { Result } from '../types/Result';
import { Tick } from '../types/Tick';

function* displayHisto(action: GetHistoAction): SagaIterator {
  const { data, userInfo } = action.payload;
  const { worksheetName } = data;
  const title = 'Historical data error';
  const maxLengthNum = 200_000;

  const sheetName = isNullOrEmptyString(worksheetName) ? 'Histo - Ticker' : worksheetName;
  const exist = yield call(sheetExist, sheetName);

  let isWritable = true;

  if (exist) {
    yield put(AppActions.existingSheetDialogOpen);
    const { yes } = yield race({
      yes: take(AppType.EXISTING_SHEET_DIALOG_YES),
      no: take(AppType.EXISTING_SHEET_DIALOG_NO),
    });
    isWritable = !!yes;
    if (isWritable) {
      yield call(deleteSheet, sheetName);
    }
  }

  if (isWritable) {
    yield put(HistoActions.setStatusToFetching);
    const response: Result<Tick[]> = yield call(api.histo, data, userInfo);
    if (isResultError(response)) {
      yield put(AppActions.errorPush(response.error));
    } else if (response.data == null || response.data.length === 0) {
      yield put(AppActions.errorPush({
        title,
        message: 'No data found.',
      }));
    } else if (response.data.length > maxLengthNum) {
      const dataLength = response.data.length.toLocaleString();
      const maxLength = maxLengthNum.toLocaleString();
      yield put(AppActions.errorPush({
        title,
        message: `Too many data returned to be displayed (${dataLength} / ${maxLength})`,
      }));
    } else {
      yield put(HistoActions.setStatusToSaving);
      const res = yield call(
        displayHistoExcel,
        sheetName,
        data.tickers,
        data.greeks,
        response.data,
      );
      if (isResultError(res)) {
        yield put(AppActions.errorPush(res.error));
      }
    }
    yield put(HistoActions.setStatusToDefault);
  }
}

export default function* watchHisto(): SagaIterator {
  yield takeLeading(Type.GET, displayHisto);
}
