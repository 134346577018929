import React, {
  FunctionComponent,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
} from '@fluentui/react';

import Progress from '../Progress';

import * as AppActions from '../../actions/app';
import { State } from '../../types/State';

const ExistingSheetDialog: FunctionComponent = (): React.ReactElement => {
  const isOfficeInitialized = useSelector(
    (state: State): boolean => state.app.isOfficeInitialized,
  );

  const isDisplayed = useSelector(
    (state: State): boolean => state.app.displayExistingSheetDialog,
  );

  const dispatch = useDispatch();

  const handleOverwrite = useCallback(
    (overwrite: boolean): void => {
      if (overwrite) {
        dispatch(AppActions.existingSheetDialogYes);
      } else {
        dispatch(AppActions.existingSheetDialogNo);
      }
      dispatch(AppActions.existingSheetDialogClose);
    },
    [dispatch],
  );

  if (!isOfficeInitialized) {
    return (
      <Progress />
    );
  }

  return isDisplayed && (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'A worksheet with the same name already exists',
        subText: 'Overwrite the previous worksheet ?',
        showCloseButton: false,
      }}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={(): void => handleOverwrite(true)}
          text="Yes"
        />
        <DefaultButton
          onClick={(): void => handleOverwrite(false)}
          text="No"
        />
      </DialogFooter>
    </Dialog>
  );
};

export default ExistingSheetDialog;
