import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  PrimaryButton,
  TextField,
} from '@fluentui/react';

import SheetDashBoard from './SheetDashBoard';

import * as DailySdrActions from '../../actions/dailySdr';
import LoadingDialog from '../LoadingDialog';

import { UserInfo } from '../../types/UserInfo';
import { State } from '../../types/State';
import LoadingStatus from '../../types/enums/LoadingStatus';

const DailySdr: React.FunctionComponent = (): React.ReactElement => {
  const [worksheetName, setWorksheetName] = useState('');

  const userInfo = useSelector((state: State): UserInfo => state.app.userInfo);
  const loadingStatus = useSelector(
    (state: State): LoadingStatus => state.dailySdr.loadingStatus,
  );

  const isStarted = useSelector(
    (state: State): boolean => state.dailySdr.isStarted,
  );

  const dispatch = useDispatch();

  const handleDailySdrSubmit = useCallback(
    (): void => {
      dispatch(DailySdrActions.subscribe(
        worksheetName,
        isStarted,
        userInfo,
        dispatch,
      ));
    },
    [
      worksheetName,
      isStarted,
      userInfo,
      dispatch,
    ],
  );

  return (
    <div className="container_commandsContent">
      <h1>Realtime Swap Data Repository</h1>
      <p>
        {'Select a range of tickers in a column and click the button to add them to the list of '
        + 'real-time data. Cells will be updated in real-time.'}
      </p>
      <form
        onSubmit={
          (e: FormEvent<HTMLFormElement>): void => {
            e.preventDefault();
            return handleDailySdrSubmit();
          }
        }
      >
        <TextField
          type="text"
          className="DailySdr_input"
          label="Worksheet Name"
          value={worksheetName}
          onChange={
            (e: ChangeEvent<HTMLInputElement>): void => setWorksheetName(e.target.value)
          }
        />
        <PrimaryButton
          text="Insert worksheet"
          type="submit"
        />
      </form>
      <SheetDashBoard />
      <LoadingDialog
        isLoading={loadingStatus === LoadingStatus.connecting}
        title="Connecting..."
        subText="Currently connecting to realtime Swap Data recovery feed."
      />
      <LoadingDialog
        isLoading={loadingStatus === LoadingStatus.fetching}
        title="Downloading..."
        subText="Currently loading Daily Sdr data."
      />
      <LoadingDialog
        isLoading={loadingStatus === LoadingStatus.saving}
        title="Saving..."
        subText="Saving realtime Swap Data worksheet settings."
      />
    </div>
  );
};

export default DailySdr;
