import * as React from 'react';
import { isNullOrEmptyString } from '../../utils/helpers';

export interface IHeaderProps {
  userName?: string;
  instance?: string;
  CSSClass?: string;
  logoSrc?: string;
}

const Header: React.FunctionComponent<IHeaderProps> = ({
  userName,
  instance,
  CSSClass,
  logoSrc,
}): React.ReactElement => {
  const headerStyle = isNullOrEmptyString(CSSClass)
    ? ''
    : CSSClass;
  const headerLogo = isNullOrEmptyString(logoSrc)
    ? 'img/logo-white-inset-32.png'
    : logoSrc;

  return (
    <header className={`header ${headerStyle}`}>
      <div className="header_logoContainer">
        <img className="header_logo" src={headerLogo} alt="OTCStreaming logo" />
        <span className="text-bold">OTC</span>
        <span className="text-light">Streaming</span>
      </div>
      {!isNullOrEmptyString(userName) && !isNullOrEmptyString(instance) && (
        <div className="header_infoContainer">
          <div>{userName}</div>
          <div>{`instance: ${instance}`}</div>
        </div>
      )}
    </header>
  );
};

export default Header;
