import { Dispatch } from 'redux';

import * as AppActions from '../actions/app';

async function onDeleted(
  args: Excel.WorksheetDeletedEventArgs,
  dispatch: Dispatch,
): Promise<void> {
  await Excel.run(
    { delayForCellEdit: true },
    async (): Promise<void> => {
      const { worksheetId } = args;
      dispatch(AppActions.onDeleteExcelWorkSheet(worksheetId, dispatch));
    },
  );
}

export default async function registerOnDeletedHandler(dispatch: Dispatch): Promise<void> {
  await Excel.run(
    { delayForCellEdit: true },
    async (ctx): Promise<void> => {
      ctx.workbook.worksheets.onDeleted.add((args): Promise<void> => onDeleted(args, dispatch));
    },
  );
}
