import { prefixMaker } from '../utils/string';

const prefix = prefixMaker('REALTIMETICKER');

export const SUBSCRIPTIONS = prefix('SUBSCRIPTIONS');

export const INIT = prefix('INIT');

export const STATUS_DEFAULT = prefix('STATUS_DEFAULT');
export const STATUS_FETCHING = prefix('STATUS_FETCHING');
export const STATUS_SAVING = prefix('STATUS_SAVING');

export const START_LOOP = prefix('START_LOOP');
export const STOP_LOOP = prefix('STOP_LOOP');
export const LOOP = prefix('LOOP');

export const STORE_SUBSCRIPTION = prefix('STORE_SUBSCRIPTION');
export const SUBSCRIBE = prefix('SUBSCRIBE');
export const UNSUBSCRIBE = prefix('UNSUBSCRIBE');
export const TOGGLE = prefix('TOGGLE');
export const UPDATE_SUBSCRIPTION = prefix('UPDATE_SUBSCRIPTION');
