import { PriceDirection, PriceType, ProductType, Standard } from '@ht/otcstreaming';
import { getAppUrl, getHomePageUrl } from './env';

import formatDate from './date';

import { GetHistoRequestData } from '../types/Histo';
import { Best } from '../types/Best';
import { UserInfo } from '../types/UserInfo';
import { Result } from '../types/Result';
import InstanceTypes from '../types/enums/InstanceTypes';
import { Tick } from '../types/Tick';

function checkStatus(response: Response): Error | Body {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    throw new Error('Your account isn\'t activated.');
  }

  throw new Error('The user name or password is incorrect.');
}

const timeoutStr = 'timeout';
const genericErrorMsg = 'Server didn\'t respond, try to reload the add-in or try again later.';
const timeoutErrorMsg = 'Looks like the server is taking to long to respond, the request scope might be too broad';

function fetchWithTimeout(
  url: RequestInfo,
  options: RequestInit,
  timeout = 10000,
): Promise<Response> {
  return Promise.race([
    fetch(url, options),
    new Promise<Response>((_, reject) => setTimeout(() => reject(new Error(timeoutStr)), timeout)),
  ]);
}

const api = {
  login(
    userName: string,
    password: string,
    env: InstanceTypes,
    appEnv: string,
  ): Promise<Result<string>> {
    const appUrl = getAppUrl(env, appEnv);
    return fetchWithTimeout(`${appUrl}/api/account/token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userName,
        password,
      }),
    })
      .then(checkStatus)
      .then((response: Body): PromiseLike<{ token: string; }> => response.json())
      .then((respData) => ({ data: respData.token }))
      .catch((e): Result<string> => ({
        error: {
          title: 'Historical data error',
          message: e.message,
        },
      }));
  },

  histo(data: GetHistoRequestData, userInfo: UserInfo): Promise<Result<Tick[]>> {
    const {
      tickers,
      productTypes,
      priceDirections,
      priceTypes,
      startDate,
      endDate,
      dealers,
      switchPrice,
      greeks,
    } = data;
    const { env, appEnv, token } = userInfo;
    const url = getAppUrl(env, appEnv);

    const params = {
      tickers: tickers.replace(/\s/g, ''),
      timezone: 'UTC',
      productTypes: productTypes.length > 0
        ? productTypes.map((type: number): string => ProductType[type]).toString()
        : '',
      priceDirections: priceDirections.length > 0
        ? priceDirections.map((type: number): string => PriceDirection[type]).toString()
        : '',
      priceTypes: priceTypes.length > 0
        ? priceTypes.map((type: number): string => PriceType[type]).toString()
        : '',
      startDate: startDate != null ? formatDate(startDate) : '',
      endDate: endDate != null ? formatDate(endDate, true) : '',
      dealers,
      greeks,
      includeFeatures: false,
      switch: switchPrice,
    };

    return fetchWithTimeout(`${url}/api/histo/ticker`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    }).then(checkStatus)
      .then((response: Body): PromiseLike<{ ticks: Tick[]; }> => response.json())
      .then((respData): Result<Tick[]> => ({ data: respData.ticks }))
      .catch((e): Result<Tick[]> => ({
        error: {
          title: 'Historical data error',
          message: e.message === timeoutStr ? timeoutErrorMsg : genericErrorMsg,
        },
      }));
  },

  dailySdr(userInfo: UserInfo): Promise<Result<Standard[]>> {
    const { appEnv } = userInfo;
    const url = getHomePageUrl(appEnv);
    return fetchWithTimeout(`${url}/api/session/current/all`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(checkStatus)
      .then((response: Body): PromiseLike<Standard[]> => response.json())
      .then((respData): Result<Standard[]> => ({ data: respData }))
      .catch((e): Result<Standard[]> => ({
        error: {
          title: 'Realtime Swap Data Repository error',
          message: e.message === timeoutStr ? timeoutErrorMsg : genericErrorMsg,
        },
      }));
  },

  realTimeTicker(userInfo: UserInfo, ticker: string): Promise<Result<Best>> {
    const { env, appEnv, token } = userInfo;
    const url = getAppUrl(env, appEnv);
    return fetchWithTimeout(`${url}/api/realtime/best?productMnemonic=${ticker}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(checkStatus)
      .then((response: Body): Promise<Best> => response.json())
      .then((bests): Result<Best> => ({ data: bests }))
      .catch((e): Result<Best> => ({
        error: {
          title: 'Realtime Ticker error',
          message: e.message === timeoutStr ? timeoutErrorMsg : genericErrorMsg,
        },
      }));
  },
};

export default api;
