import {
  takeEvery,
  takeLeading,
  call,
  put,
} from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import { openDialog, sendLoginSuccess } from '../excel/auth';
import * as AuthActions from '../actions/auth';

import * as Type from '../constants/auth';

import api from '../utils/fetch';
import { UserInfo } from '../types/UserInfo';
import { Result } from '../types/Result';
import { isResultError } from '../utils/helpers';
import { LoginAction, AuthOpenDialogAction } from '../types/Auth';

function* login(action: LoginAction): SagaIterator {
  const {
    userName,
    password,
    env,
    appEnv,
  } = action.payload;
  const response: Result<string> = yield call(api.login, userName, password, env, appEnv);

  if (isResultError(response)) {
    yield put(AuthActions.setLoginError(response.error.message));
    return;
  }

  const userInfo: UserInfo = {
    appEnv,
    env,
    token: response.data,
    userName,
  };
  yield call(sendLoginSuccess, userInfo);
}

function* authDialogOpen(action: AuthOpenDialogAction): SagaIterator {
  const { dispatch } = action.payload;
  try {
    yield call(openDialog, dispatch);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export default function* watchAuthDialog(): SagaIterator {
  yield takeLeading(Type.DIALOG_OPEN, authDialogOpen);
  yield takeEvery(Type.LOGIN, login);
}
