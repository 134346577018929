import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Progress from '../Progress';
import Intro from '../Intro';
import Commands from '../Commands';
import ErrorDialog from '../ErrorDialog';

import { isNullOrEmptyObject, isNullOrEmptyString } from '../../utils/helpers';
import { defineConnection, bindConnectionOnClose } from '../../utils/signalR';
import ExistingSheetDialog from '../ExistingSheetDialog';

import { Error, State, StateApp } from '../../types/State';
import { UserInfo } from '../../types/UserInfo';
import { appInitialize } from '../../actions/app';

const App: FunctionComponent<StateApp> = (): React.ReactElement => {
  const [error, setError] = useState<Error>(null);

  const isOfficeInitialized = useSelector(
    (state: State): boolean => state.app.isOfficeInitialized,
  );
  const userInfo = useSelector(
    (state: State): UserInfo => state.app.userInfo,
  );
  const errors = useSelector(
    (state: State): Error[] => state.app.errors,
  );

  const dispatch = useDispatch();

  useEffect((): void => {
    if (!isNullOrEmptyObject(userInfo) && userInfo.appEnv != null) {
      defineConnection(userInfo.appEnv);
      bindConnectionOnClose();
      dispatch(appInitialize(userInfo, dispatch));
    }
  }, [userInfo, dispatch]);

  useEffect((): void => {
    if (errors.length !== 0) {
      setError(errors[errors.length - 1]);
    } else {
      setError(null);
    }
  }, [errors]);

  if (!isOfficeInitialized) {
    return (
      <Progress />
    );
  }
  const content = (isNullOrEmptyObject(userInfo) && isNullOrEmptyString(userInfo.token))
    ? (<Intro />)
    : (
      <Commands
        userName={userInfo.userName}
        token={userInfo.token}
        env={userInfo.env}
        appEnv={userInfo.appEnv}
      />
    );

  return (
    <div>
      {error && <ErrorDialog error={error} />}
      <ExistingSheetDialog />
      {content}
    </div>
  );
};

export default App;
