import React, { FormEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton, Toggle } from '@fluentui/react';

import LoadingDialog from '../LoadingDialog';
import RangeDashBoard from './RangeDashBoard';

import * as RealtimeTickerActions from '../../actions/realtimeTicker';

import { State } from '../../types/State';
import { UserInfo } from '../../types/UserInfo';
import { SubscribeAction } from '../../types/RealtimeTicker';
import LoadingStatus from '../../types/enums/LoadingStatus';

const RealtimeTicker: React.FunctionComponent = (): React.ReactElement => {
  const [hasHeaders, setHasHeaders] = useState<boolean>(true);

  const userInfos = useSelector((state: State): UserInfo => state.app.userInfo);
  const loadingStatus = useSelector(
    (state: State): LoadingStatus => state.realtimeTicker.loadingStatus,
  );

  const dispatch = useDispatch();

  const handleRealtimeTickerSubmit = useCallback(
    (): SubscribeAction => (
      dispatch(
        RealtimeTickerActions.subscribe(
          hasHeaders,
          userInfos,
          dispatch,
        ),
      )
    ),
    [hasHeaders, userInfos, dispatch],
  );

  return (
    <div className="container_commandsContent">
      <h1>Realtime Ticker Data</h1>
      <p>
        {'Select a range of tickers in a column and click the button to add them to the list '
        + 'of real-time data. Cells will be updated in real-time.'}
      </p>
      <form
        onSubmit={
          (e: FormEvent<HTMLFormElement>): SubscribeAction => {
            e.preventDefault();
            return handleRealtimeTickerSubmit();
          }
        }
      >
        <Toggle
          checked={hasHeaders}
          onChange={(): void => setHasHeaders(!hasHeaders)}
          label="Display Table Headers"
          onText="On"
          offText="Off"
        />
        <PrimaryButton
          text="Insert Table"
          type="submit"
        />
      </form>
      <RangeDashBoard />
      <LoadingDialog
        isLoading={loadingStatus === LoadingStatus.fetching}
        title="Downloading..."
        subText="Currently loading Realtime Ticker data."
      />
      <LoadingDialog
        isLoading={loadingStatus === LoadingStatus.saving}
        title="Saving..."
        subText="Saving realtime Realtime Ticker settings."
      />
    </div>
  );
};

export default RealtimeTicker;
