import { Dispatch } from 'redux';

import * as Type from '../constants/auth';

import {
  AuthOpenDialogAction,
  LoginAction,
  SetLoginErrorAction,
} from '../types/Auth';
import InstanceTypes from '../types/enums/InstanceTypes';

export const authDialogOpen = (
  dispatch: Dispatch,
): AuthOpenDialogAction => ({
  type: Type.DIALOG_OPEN,
  payload: {
    dispatch,
  },
});

export const login = (
  userName: string,
  password: string,
  env: InstanceTypes,
  appEnv: string,
): LoginAction => ({
  type: Type.LOGIN,
  payload: {
    userName,
    password,
    env,
    appEnv,
  },
});

export const setLoginError = (
  description: string,
): SetLoginErrorAction => ({
  type: Type.LOGIN_FAILURE,
  payload: {
    description,
  },
});
