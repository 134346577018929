import React, { FunctionComponent, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { PrimaryButton } from '@fluentui/react';

import Histo from './Histo';
import DailySdr from './DailySdr';
import RealtimeTicker from './RealtimeTicker';

import Header from '../Header';
import PivotNav from '../PivotNav';

import * as AppActions from '../../actions/app';

import { UserInfo } from '../../types/UserInfo';
import { Action } from '../../types/Action';

const Commands: FunctionComponent<UserInfo> = ({
  userName,
  appEnv,
  env,
}): ReactElement => {
  const dispatch = useDispatch();

  return (
    <div className="container_commands">
      <div className="container_headerContent">
        <Header
          userName={userName}
          instance={appEnv !== '' ? appEnv : env}
          CSSClass="header-small"
          logoSrc="img/logo-white-inset-16.png"
        />
        <div className="container">
          <PivotNav />
          <Redirect from="/" to="/ticker" />
          <Route path="/ticker" component={RealtimeTicker} />
          <Route path="/dailySdr" component={DailySdr} />
          <Route path="/histo" component={Histo} />
        </div>
      </div>
      <div className="container_footer">
        <PrimaryButton
          text="Logout"
          type="button"
          className="container_footer_logout"
          onClick={(): Action => dispatch(AppActions.logout)}
        />
      </div>
    </div>
  );
};

export default Commands;
