import { Dispatch } from 'redux';

import * as AppActions from '../actions/app';

import { Error } from '../types/State';
import { UserInfo } from '../types/UserInfo';

let dialog: Office.Dialog;

interface DataMsg { message: string | boolean }
interface ErrorMsg { error: number }
type Msg = DataMsg | ErrorMsg;

function isDataMsg(arg: Msg): arg is DataMsg {
  return (arg as DataMsg).message !== undefined;
}

function processMessage(arg: Msg, dispatch: Dispatch): void {
  if (isDataMsg(arg)) {
    const data: UserInfo = JSON.parse(arg.message as string);
    dialog.close();
    dispatch(AppActions.setSessionInfos(data));
  }
}

export function sendLoginSuccess(response: UserInfo): void {
  Office.context.ui.messageParent(JSON.stringify(response));
}

export function openDialog(dispatch: Dispatch): void {
  Office.context.ui.displayDialogAsync(
    `${window.location.origin}/auth`,
    { height: 60, width: 40, promptBeforeOpen: false },
    (asyncResult: Office.AsyncResult<Office.Dialog>): void => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        let error: Error;
        if (asyncResult.error.code === 12007) {
          error = { title: 'Auth Error', message: 'Login window is already open' };
        } else {
          error = { title: 'Auth Error', message: 'Try again later or contact the support' };
        }
        dispatch(AppActions.errorPush(error));
      } else {
        dialog = asyncResult.value;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          (arg): void => processMessage(arg, dispatch),
        );
      }
    },
  );
}
