import createReducer from '../utils/createReducer';

import * as Type from '../constants/dailySdr';

import { StateDailySdr } from '../types/State';
import LoadingStatus from '../types/enums/LoadingStatus';
import {
  DailySdrSub,
  InitAction,
  StoreSubscribeAction,
  UnsubscribeAction,
  RenameSheetAction,
  UpdateSubscriptionAction,
} from '../types/DailySdr';

const initialState: StateDailySdr = {
  subscriptions: [],
  loadingStatus: LoadingStatus.default,
  isStarted: false,
};

export default createReducer(initialState, {
  [Type.INIT](
    state: StateDailySdr,
    action: InitAction,
  ): StateDailySdr {
    const sheetsSubscription = [...action.payload];
    return {
      ...state,
      subscriptions: sheetsSubscription,
    };
  },
  [Type.HUB_CONNECTED](
    state: StateDailySdr,
  ): StateDailySdr {
    return {
      ...state,
      isStarted: true,
    };
  },
  [Type.STATUS_FETCHING](
    state: StateDailySdr,
  ): StateDailySdr {
    return {
      ...state,
      loadingStatus: LoadingStatus.fetching,
    };
  },
  [Type.STATUS_SAVING](
    state: StateDailySdr,
  ): StateDailySdr {
    return {
      ...state,
      loadingStatus: LoadingStatus.saving,
    };
  },
  [Type.STATUS_DEFAULT](
    state: StateDailySdr,
  ): StateDailySdr {
    return {
      ...state,
      loadingStatus: LoadingStatus.default,
    };
  },
  [Type.STORE_SUBSCRIPTION](
    state: StateDailySdr,
    action: StoreSubscribeAction,
  ): StateDailySdr {
    return {
      ...state,
      subscriptions: [
        ...state.subscriptions,
        action.payload,
      ],
    };
  },
  [Type.UNSUBSCRIBE](
    state: StateDailySdr,
    action: UnsubscribeAction,
  ): StateDailySdr {
    const { sheetId } = action.payload;
    const sheetsSubscription = state.subscriptions.filter(
      (sheetStatus: DailySdrSub): boolean => sheetStatus.sheetId !== sheetId,
    );
    return {
      ...state,
      subscriptions: sheetsSubscription,
    };
  },
  [Type.RENAME_SHEET](
    state: StateDailySdr,
    action: RenameSheetAction,
  ): StateDailySdr {
    const { sub, shouldRename } = action.payload;
    if (!shouldRename) {
      return state;
    }
    const newSubs = state.subscriptions.map((s) => {
      if (s.sheetId !== sub.sheetId) {
        return s;
      }
      return sub;
    });

    return {
      ...state,
      subscriptions: newSubs,
    };
  },
  [Type.UPDATE_SUBSCRIPTION](
    state: StateDailySdr,
    action: UpdateSubscriptionAction,
  ): StateDailySdr {
    const sub = action.payload;
    const newSubs = state.subscriptions.map((s) => {
      if (s.sheetId !== sub.sheetId) {
        return s;
      }
      return { ...sub };
    });

    return {
      ...state,
      subscriptions: newSubs,
    };
  },
});
