export async function sheetExist(sheetName: string): Promise<boolean> {
  return Excel.run({ delayForCellEdit: true }, async (ctx): Promise<boolean> => {
    const sameNameWorkSheet = ctx.workbook.worksheets.getItemOrNullObject(sheetName);
    await ctx.sync();
    return !sameNameWorkSheet.isNullObject;
  });
}

export async function deleteSheet(sheetName: string): Promise<void> {
  await Excel.run({ delayForCellEdit: true }, async (ctx): Promise<void> => {
    const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetName);
    await ctx.sync();
    if (!sheet.isNullObject) {
      sheet.delete();
    }
    await ctx.sync();
  });
}

export async function getSheetNameById(
  sheetId: string,
): Promise<string> {
  return Excel.run({ delayForCellEdit: true }, async (ctx): Promise<string> => {
    const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId);
    sheet.load('name');
    await ctx.sync();

    return !sheet.isNullObject ? sheet.name : null;
  });
}
