import { PriceType, PriceDirection, ProductType } from '@ht/otcstreaming';
import { Action } from '../types/Action';
import * as Type from '../constants/histo';

import { GetHistoAction } from '../types/Histo';
import { UserInfo } from '../types/UserInfo';

export const setStatusToDefault: Action = {
  type: Type.STATUS_DEFAULT,
};

export const setStatusToFetching: Action = {
  type: Type.STATUS_FETCHING,
};

export const setStatusToSaving: Action = {
  type: Type.STATUS_SAVING,
};

export const getHisto = (
  worksheetName: string,
  tickers: string,
  productTypes: ProductType[],
  priceTypes: PriceType[],
  priceDirections: PriceDirection[],
  startDate: Date,
  endDate: Date,
  dealers: string,
  switchPrice: boolean,
  greeks: boolean,
  userInfo: UserInfo,
): GetHistoAction => ({
  type: Type.GET,
  payload: {
    data: {
      worksheetName,
      tickers,
      productTypes,
      priceTypes,
      priceDirections,
      startDate,
      endDate,
      dealers,
      switchPrice,
      greeks,
    },
    userInfo,
  },
});
